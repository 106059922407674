import React, { useState } from 'react'
import Form from '../../../Forms/Form'
import { API_URL } from '../../../../../../Utilities/APIs'
import FormSubmitButton from '../../../Forms/FormSubmitButton/FormSubmitButton'
import ShortTextInput from '../../../Forms/FormInputs/ShortTextInput/ShortTextInput'
import axios from 'axios'

function CreateCollectionForm({ triggerFetch, category, setShowThirdModal, setShowCreateCollectionForm }) {

    const [name, setName] = useState('')

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = { category, name }

        const response = await axios.post(API_URL + 'mediaCollections/', itemData)

        if (response) {

            setShowThirdModal(false);
            setShowCreateCollectionForm(false);

            triggerFetch()
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <div className="media_label">
                <label className='input_field_label'>Category</label>
                <p>{category}</p>
            </div>
            <ShortTextInput
                label={`Name`}
                value={name}
                placeholder={`Enter Name`}
                setState={setName}
            />

            <FormSubmitButton text='Create Collection' />
        </Form>
    )
}

export default CreateCollectionForm