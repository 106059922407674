import { useState, useEffect } from "react"
import axios from "axios"
import { BLOGS_API, IMAGE_URL } from "../../../Utilities/APIs"

function ViewBlog({

    //common props
    targetID, employee

}) {

    const [blogInfo, setBlogInfo] = useState(null)

    useEffect(() => {

        async function fetchAndSetBlogInfo() {

            const { data } = await axios.get(BLOGS_API + 'getSingleBlog/' + targetID)
            setBlogInfo(data);
        }
        fetchAndSetBlogInfo()
    }, [targetID])

    return (
        <div className="crud_view_content">

            {blogInfo &&
                 <>
                 <h1>Service</h1>
                 <p>{blogInfo.service?.name}</p>

                 <h1>Name</h1>
                 <p>{blogInfo.name}</p>

                 <h1>News Portal</h1>
                 <p>{blogInfo.tag}</p>

                 <h1>Link</h1>
                 <p>{blogInfo.link}</p>

                 <h1>Image</h1>
                 <img src={IMAGE_URL + blogInfo.image} alt="" />
                 
                 <h1>Publish Date</h1>
                 <p>{new Date(blogInfo.publishDate).toLocaleDateString()}</p>

                 <h1>Precedence</h1>
                 <p>{blogInfo.precedence}</p>

             </>
            }
        </div>
    )
}

export default ViewBlog