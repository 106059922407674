import React from 'react'
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard'
import { RiBookOpenLine, RiDoorOpenLine,  RiFileTextLine,  RiImageLine, RiMessage2Line, RiProfileLine,   RiVideoLine } from 'react-icons/ri'

function Dashboard() {
  return (
    <NavCardList numOfCards={'eight'}>
      <NavCard cardName={'Header/Hero Section'} navCardLink={'/homeSliders'} ><RiImageLine /></NavCard>
      <NavCard cardName={'Home Videos'} navCardLink={'/homeVideos'} ><RiVideoLine /></NavCard>
      <NavCard cardName={'Work Features'} navCardLink={'/workFeatures'} ><RiProfileLine /></NavCard>
      {/* <NavCard cardName={'Homepage Articles'} navCardLink={'/featuredArticles'} ><RiArticleLine /></NavCard> */}
      <NavCard cardName={"Mostafiz's Global Stance"} navCardLink={'/successStories'} ><RiBookOpenLine /></NavCard>
      <NavCard cardName={'Home About'} navCardLink={'/about'} ><RiDoorOpenLine /></NavCard>
      <NavCard cardName={'Home Page Section Titles'} navCardLink={'/homePageSectionTitles'} ><RiFileTextLine /></NavCard>
      <NavCard cardName={'Home Testimonials'} navCardLink={'/testimonials'} ><RiMessage2Line /></NavCard>

      {/* <NavCard cardName={'Services'} navCardLink={'/services'} ><RiServiceLine /></NavCard>
      <NavCard cardName={'Sub Services'} navCardLink={'/subServices'} ><RiHardDriveLine /></NavCard> */}
    </NavCardList>
  )
}

export default Dashboard