import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'
import { RiBook2Line, RiBookOpenLine, RiChatQuoteLine,  RiFocus2Line, RiFolder2Line, RiImageLine,  RiMiniProgramLine,  RiStackFill } from 'react-icons/ri'
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard'

function Others() {
  return (
    <NavCardList numOfCards={'eight'}>
      <NavCard cardName={'Events'} navCardLink={'/events'} ><RiImageLine /></NavCard>
      <NavCard cardName={'Unlocking Expo Excellence'} navCardLink={'/expoWriteUp'} ><RiFocus2Line /></NavCard>
      <NavCard cardName={'Programs'} navCardLink={'/programs'} ><RiMiniProgramLine /></NavCard>
      {/* <NavCard cardName={'Blog'} navCardLink={'/blogs'} ><RiBook3Line /></NavCard> */}
      <NavCard cardName={'Book'} navCardLink={'/bookSliders'} ><RiBook2Line /></NavCard>
      <NavCard cardName={'Magazine'} navCardLink={'/magazineSliders'} ><RiBookOpenLine /></NavCard>
      <NavCard cardName={'Exhibitions'} navCardLink={'/exhibitions'} ><RiStackFill /></NavCard>
      <NavCard cardName={'Service Testimonials'} navCardLink={'/serviceTestimonials'} ><RiChatQuoteLine /></NavCard>
      <NavCard cardName={'Image Galleries'} navCardLink={'/imageGalleries'} ><RiFolder2Line /></NavCard>
    </NavCardList>
  )
}

export default Others