import axios from "axios"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { SUCCESS_STORIES_API } from "../../../Utilities/APIs"
import CreateButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton"
import CRUDTable from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable"
import CRUDTableHeader from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader"
import CRUDth from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth"
import ShortTextCell from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell"
import ImageCell from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell"
import ViewButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton"
import EditButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton"
import DeleteButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton"
import Modal from "../../Partials/Elements/Modal/Modal"
import CRUDDeleteBoilerPlate from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDeleteBoilerPlate/CRUDDeleteBoilerPlate"
import ViewSuccessStory from "./ViewSuccessStory"
import CreateSuccessStory from "./CreateSuccessStory"
import UpdateSuccessStory from "./UpdateSuccessStory"


function SuccessStories() {

    const [successStories, setSuccessStories] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [targetID, setTargetID] = useState(null)
    const [showViewSection, setShowViewSection] = useState(false)
    const [showCreateForm, setShowCreateForm] = useState(false)
    const [showUpdateForm, setShowUpdateForm] = useState(false)
    const [showDeleteSection, setShowDeleteSection] = useState(false)
    const [toggleFetch, setToggleFetch] = useState(false)
    const { employee } = useSelector(state => state.auth)

    useEffect(() => {

        async function fetchAndSetSuccessStories() {
            const { data } = await axios.get(SUCCESS_STORIES_API + 'getAllSuccessStoriesForAdmin')
            setSuccessStories(data)
        }
        fetchAndSetSuccessStories()


    }, [toggleFetch])

    function handleClose() {
        setShowModal(false);
        setShowCreateForm(false);
        setShowViewSection(false);
        setShowUpdateForm(false);
        setShowDeleteSection(false);
    }

    function triggerFetch() {
        setToggleFetch(prevState => !prevState)
    }

    async function handleOnDragEnd(result) {
        const sourcePrecedence = result.source.index + 1
        const destinationPrecedence = result.destination.index + 1
        if (sourcePrecedence === destinationPrecedence) {
          return
        } else {
          const response = await axios.patch(`${SUCCESS_STORIES_API}reorderSuccessStory/${sourcePrecedence}/${destinationPrecedence}`)
          if (response) {
            triggerFetch()
          }
          console.log(sourcePrecedence, ' ', destinationPrecedence)
        }
    
      }

    return (
        <>
            <div className='crud_holder'>
                <div className="body_header">
                    <h1 className="heading">{`Success Stories (${successStories?.length} in total)`}</h1>
                    <CreateButton
                        screenTopicSingular='Success Story'
                        setShowModal={setShowModal}
                        setShowCreateForm={setShowCreateForm}
                    />
                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th='Service' />
                        <CRUDth th='Name' />
                        <CRUDth th='Image' />
                        <CRUDth th='Actions' />
                    </CRUDTableHeader>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId='bleh'>
                        {(provided) => (
                            <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                {successStories?.map((successStory, index) => (
                                    <Draggable key={successStory._id} draggableId={successStory._id} index={index}>
                                            {(provided) => (
                                <tr
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                >
                                        <ShortTextCell text={successStory.service?.name} />
                                        <ShortTextCell text={successStory.name?.length > 25 ? successStory.name.slice(0, 25) + '...' : successStory.name} />
                                        <ImageCell imgSrc={ successStory.image} />

                                        <td className="action_button_cell">
                                            <ViewButton
                                                setShowModal={setShowModal}
                                                setShowViewSection={setShowViewSection}
                                                targetID={successStory._id}
                                                setTargetID={setTargetID}
                                            />
                                            <EditButton
                                                setShowModal={setShowModal}
                                                setShowUpdateForm={setShowUpdateForm}
                                                targetID={successStory._id}
                                                setTargetID={setTargetID}
                                            />
                                            <DeleteButton
                                                setShowModal={setShowModal}
                                                setShowDeleteSection={setShowDeleteSection}
                                                targetID={successStory._id}
                                                setTargetID={setTargetID}
                                            />

                                        </td>
                                </tr>
                        )}
                                    </Draggable>
                                        ))}
                                {provided.placeholder}
                            </tbody>
                        )}
                        </Droppable>
                    </DragDropContext>
                    {/* <tbody>
                        {successStories?.map(successStory => (
                            <CRUDTableRow key={successStory._id}>
                                <ShortTextCell text={successStory.service?.name} />
                                <ShortTextCell text={successStory.name?.length > 25 ? successStory.name.slice(0, 25) + '...' : successStory.name} />
                                <ImageCell imgSrc={ successStory.image} />
                                <ShortTextCell text={successStory.precedence} />


                                <td className="action_button_cell">
                                    <ViewButton
                                        setShowModal={setShowModal}
                                        setShowViewSection={setShowViewSection}
                                        targetID={successStory._id}
                                        setTargetID={setTargetID}
                                    />
                                    <EditButton
                                        setShowModal={setShowModal}
                                        setShowUpdateForm={setShowUpdateForm}
                                        targetID={successStory._id}
                                        setTargetID={setTargetID}
                                    />
                                    <DeleteButton
                                        setShowModal={setShowModal}
                                        setShowDeleteSection={setShowDeleteSection}
                                        targetID={successStory._id}
                                        setTargetID={setTargetID}
                                    />

                                </td>
                            </CRUDTableRow>
                        ))}
                    </tbody> */}
                </CRUDTable>
            </div>
            {showModal &&
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={
                        (showCreateForm && `Create Success Story`) ||
                        (showUpdateForm && `Update Success Story`) ||
                        (showDeleteSection && `Delete Success Story`)
                    }
                >
                    {
                        showCreateForm &&
                        <CreateSuccessStory
                            employee={employee}
                            setShowCreateForm={setShowCreateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    }
                    {
                        showUpdateForm &&
                        <UpdateSuccessStory
                            employee={employee}
                            targetID={targetID}
                            setShowUpdateForm={setShowUpdateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    }
                    {
                        showDeleteSection &&
                        <CRUDDeleteBoilerPlate
                            api={SUCCESS_STORIES_API}
                            targetID={targetID}
                            employee={employee}
                            setShowModal={setShowModal}
                            setShowDeleteSection={setShowDeleteSection}
                            hasName
                            triggerFetch={triggerFetch}
                        />
                    }
                </Modal>
            }

            {showModal && showViewSection &&
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={
                        (showViewSection && `View Success Story`)
                    }
                >
                    <ViewSuccessStory
                        targetID={targetID}
                        employee={employee}
                    />
                </Modal>
            }
        </>
    )
}

export default SuccessStories