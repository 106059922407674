import { ABOUT_TOP_SECTION_CHANGING_TEXTS_API } from "../../../Utilities/APIs"
import NCRUD from "../../Partials/Layouts/CRUDs/CRUDLayouts/NCRUD/NCRUD"

function AboutTopChangingTexts() {
    return (
        <NCRUD
            // common props
            api={ABOUT_TOP_SECTION_CHANGING_TEXTS_API}
            screenTopicSingular='About Top Changing Text'
            screenTopicPlural='About Top Changing Texts'


            // crud header props
            showTotalInHeading

            // crud props
            allowCreate
            allowUpdate
            allowDelete

        />
    )
}

export default AboutTopChangingTexts