import { ARTICLE_TAGS_API } from '../../../Utilities/APIs'
import NCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/NCRUD/NCRUD'

function ArticleTags() {
  return (
    <NCRUD
    // common props
    api={ARTICLE_TAGS_API}
    screenTopicSingular='Article Tag'
    screenTopicPlural='Article Tags'

    // crud header props
    showTotalInHeading

  
    
    // crud props
    allowCreate
    allowUpdate
    allowDelete

  />
  )
}

export default ArticleTags