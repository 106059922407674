import { useEffect, useState } from "react"
import axios from "axios"

import { SERVICES_API, SUCCESS_STORIES_API, SUCCESS_STORY_TAGS_API } from "../../../Utilities/APIs"
import Form from "../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import SelectInput from "../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput"
import SelectOption from "../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption"



function CreateSuccessStory({ setShowCreateForm, setShowModal, triggerFetch }) {



    const [service, setService] = useState('')
    const [name, setName] = useState('')
    const [successStoryTag, setSuccessStoryTag] = useState('')
    const [image, setImage] = useState('')
    const [linkType, setLinkType] = useState('')
    const [link, setlink] = useState('')



    const [services, setServices] = useState(null)
    const [successStoryTags, setSuccessStoryTags] = useState(null);


    useEffect(() => {
      async function getSuccessStoryTags() {
        const { data } = await axios.get(SUCCESS_STORY_TAGS_API);
        setSuccessStoryTags(data);
      }
      getSuccessStoryTags();
    }, []);

    useEffect(() => {

        async function fetchAndSetServices() {
            const { data } = await axios.get(SERVICES_API)
            setServices(data)
        }
        fetchAndSetServices()


    }, [])
    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        if (service && service !== '') itemData.append('service', service)
        itemData.append('name', name)
        if (successStoryTag && successStoryTag !== '') itemData.append('successStoryTag', successStoryTag)
        itemData.append('image', image)
        itemData.append('linkType', linkType)
        itemData.append('link', link)

        const response = await axios.post(SUCCESS_STORIES_API, itemData)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);
            triggerFetch()
        }
    }



    return (
        <>
            <Form onSubmit={handleSubmit} hasImage>
                <div className={`nav_content active`}>
                    <div className="input_group">
                        <label className='input_field_label'>
                            Service
                        </label>
                        <select
                            value={service}
                            onChange={(e) => setService(e.target.value)}
                            className="input_field"
                            placeholder="propertyCategory"
                        >
                            <option value="0" hidden>Select Service</option>
                            {services?.map((service => (
                                <option value={service._id} key={service._id}>
                                    {service?.name}
                                </option>
                            )))}
                        </select>
                    </div>
                    <ShortTextInput
                        label={`Name`}
                        value={name}
                        placeholder={`Enter Name`}
                        setState={setName}
                    />
                    <div className="input_group">
                            <label className='input_field_label'>
                              Success Story Tag
                            </label>
                            <select
                                value={successStoryTag}
                                onChange={(e) => setSuccessStoryTag(e.target.value)}
                                className="input_field"
                                placeholder="propertyCategory"
                            >
                                <option value="0" hidden>Select Success Story Tag</option>
                                {successStoryTags?.map((s => (
                                    <option value={s._id} key={s._id}>
                                        {s?.name}
                                    </option>
                                )))}
                            </select>
                        </div>
                    <SelectInput
                        label={'Link Type'}
                        value={linkType}
                        setState={setLinkType}
                    >
                        <SelectOption optionValue='' optionText="Select Link Type" />
                        <SelectOption optionValue='vedioLink' optionText="Vedio Link" />
                        <SelectOption optionValue='newsLink' optionText="News Link" />
                      
                    </SelectInput>
                    <ShortTextInput
                        label={`Link`}
                        value={link}
                        placeholder={`Enter Link`}
                        setState={setlink}
                    />

                    <ImageInput
                        fieldId='1'
                        state={image}
                        setState={setImage}
                        allowCreateImage
                    >
                        Upload Card Image
                    </ImageInput>
                   


                </div>


                <FormSubmitButton text='Create Success Story' />
            </Form>

        </>
    )
}

export default CreateSuccessStory