import { useState, useEffect } from "react"
import axios from "axios"
import { IMAGE_URL, SUCCESS_STORIES_API } from "../../../Utilities/APIs"

function ViewSuccessStory({

    //common props
    targetID, employee

}) {

    const [successStory, setSuccessStory] = useState(null)

    useEffect(() => {

        async function fetchAndSetSuccessStory() {

            const { data } = await axios.get(SUCCESS_STORIES_API + 'getSingleSuccessStory/' + targetID)
            setSuccessStory(data);
        }
        fetchAndSetSuccessStory()
    }, [targetID])

    return (
        <div className="crud_view_content">

            {successStory &&
                <>
                    <h1>Service</h1>
                    <p>{successStory.service?.name}</p>

                    <h1>Name</h1>
                    <p>{successStory.name}</p>

                    <h1>Image</h1>
                    <img src={IMAGE_URL + successStory.image} alt="" />

                    <h1>Link Type</h1>
                    <p>{successStory.linkType}</p>

                    <h1>Link</h1>
                    <p>{successStory.link}</p>


                </>
            }
        </div>
    )
}

export default ViewSuccessStory