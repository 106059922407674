import React from "react";
import { SERVICES_API, SERVICE_TESTIMONIALS_API } from "../../../Utilities/APIs";
import DLINPTSCRUD from "../../Partials/Layouts/CRUDs/CRUDLayouts/DLINPTSCRUD/DLINPTSCRUD";

function ServiceTestimonials() {
  return (
    <DLINPTSCRUD
      // common props
      api={SERVICE_TESTIMONIALS_API}
      screenTopicSingular="Service Testimonial"
      screenTopicPlural="Service Testimonials"
      // crud header props
      showTotalInHeading
      // crud props
      allowCreate
      allowUpdate
      allowDelete

      //extra field props
      textDBField="precedence"
      textName="Precedence"

      longTextDBField='designation'
      longTextName='Designation'
      // select props
      selectApi={SERVICES_API}
      selectType="parent-child"
      selectDBField="service"
      selectName="Service"
    />
  );
}

export default ServiceTestimonials;
