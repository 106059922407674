import React from 'react'
import { IMAGE_GALLERIES_API, IMAGE_GALLERY_TAGS_API } from '../../../Utilities/APIs'
import ISCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/ISCRUD/ISCRUD'

function ImageGalleries() {
  return (
    <ISCRUD
         // common props
        api ={IMAGE_GALLERIES_API}
        screenTopicSingular='Image Gallery' 
        screenTopicPlural='Image Galleries'

        
        // crud header props
        showTotalInHeading

        
        // action button props
        extraActionButton 
        extraActionButtonHandleClick 
        extraActionButtonChildren
        
        // crud props
        allowCreate 
        allowUpdate 
        allowDelete

        // select props
        selectApi ={IMAGE_GALLERY_TAGS_API}
        selectType='parent-child'
        selectDBField='imageGalleryTag'
        selectName='Image Gallery Tag'
      />

    
  )
}

export default ImageGalleries