import { SUCCESS_STORY_TAGS_API } from "../../../Utilities/APIs"
import NCRUD from "../../Partials/Layouts/CRUDs/CRUDLayouts/NCRUD/NCRUD"

function SuccessStoryTags() {
  return (
    <NCRUD
    // common props
    api={SUCCESS_STORY_TAGS_API}
    screenTopicSingular='Success Story Tag'
    screenTopicPlural='Success Story Tags'

    // crud header props
    showTotalInHeading

  
    
    // crud props
    allowCreate
    allowUpdate
    allowDelete

  />
  )
}

export default SuccessStoryTags