import { EXPO_WRITE_UPS_API } from "../../../Utilities/APIs"
import BDITCRUD from "../../Partials/Layouts/CRUDs/CRUDLayouts/BDITCRUD/BDITCRUD"



function ExpoWriteUp() {
    return (
        <BDITCRUD
            // common props
            api={EXPO_WRITE_UPS_API}
            screenTopicSingular='Unlocking Expo Excellence'
            screenTopicPlural='Unlocking Expo Excellences'

            // crud header props
            showTotalInHeading

            // crud props
            // allowCreate
            allowUpdate
            // allowDelete

            //extra field props
            textDBField='title'
            textName='Title'
            booleanName='Is Active'
            booleanDBField="isActive"
        />
    )
}

export default ExpoWriteUp