import { useState } from "react"
import axios from "axios"
import Form from "../../../Partials/Layouts/Forms/Form"
import { ABOUTS_API } from "../../../../Utilities/APIs"
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"


function CreateAbout({

    employee, setShowCreateForm, setShowModal, triggerFetch

}) {
    const [title, setTitle] = useState('')
    const [subtitle, setSubtitle] = useState('')
    const [description, setDescription] = useState('')
    const [image, setImage] = useState('')
    const [buttonText1, setButtonText1] = useState('')
    const [buttonText2, setButtonText2] = useState('')


    async function handleSubmit(e) {

        e.preventDefault()

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }
        const itemData = new FormData()

        itemData.append('title', title)
        itemData.append('subtitle', subtitle)
        itemData.append('description', description)
        itemData.append('image', image)
        itemData.append('buttonText1', buttonText1)
        itemData.append('buttonText2', buttonText2)

        const response = await axios.post(ABOUTS_API, itemData)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>

            <ShortTextInput
                label={`Title`}
                value={title}
                placeholder={`Enter Title`}
                setState={setTitle}
            />
            <ShortTextInput
                label={`Subtitle`}
                value={subtitle}
                placeholder={`Enter Subtitle`}
                setState={setSubtitle}
            />
            <ShortTextInput
                label={`Description`}
                value={description}
                placeholder={`Enter Description`}
                setState={setDescription}
            />
            <ImageInput
                fieldId='1'
                state={image}
                setState={setImage}
                allowCreateImage
            >
                Upload Card Image
            </ImageInput>
            <ShortTextInput
                label={`Button Text One`}
                value={buttonText1}
                placeholder={`Enter Button Text One`}
                setState={setButtonText1}
            />
            <ShortTextInput
                label={`Button Text Two`}
                value={buttonText2}
                placeholder={`Enter Button Text Two`}
                setState={setButtonText2}
            />

            <FormSubmitButton text='Create Hero Slider' />
        </Form>
    )
}

export default CreateAbout