import React from 'react'
import { SERVICES_API, SUB_SERVICES_API } from '../../../Utilities/APIs'
import DNPTSCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/DNPTSCRUD/DNPTSCRUD'

function SubServices() {
  return (
    <DNPTSCRUD

      // common props
      api={SUB_SERVICES_API}
      screenTopicSingular='Sub Service'
      screenTopicPlural='Sub Services'

      // crud header props
      showTotalInHeading

      // crud props
      allowCreate
      allowUpdate
      allowDelete
      textDBField='precedence'
      textName='Precedence'

      // select props
      selectApi={SERVICES_API}
      selectType="parent-child"
      selectDBField='service'
      selectName='Service'

      // field props
      maxPrecedence={6}

    />
  )
}

export default SubServices