import { ABOUT_SLIDERS_API } from "../../../Utilities/APIs"
import DINTCRUD from "../../Partials/Layouts/CRUDs/CRUDLayouts/DINTCRUD/DINTCRUD"

function AboutSliders() {
    return (
        <DINTCRUD
            // common props
            api={ABOUT_SLIDERS_API}
            screenTopicSingular='About Slider'
            screenTopicPlural='About Sliders'


            // crud header props
            showTotalInHeading

            // crud props
            allowCreate
            allowUpdate
            allowDelete


            //extra field props
            textDBField='title'
            textName='Title'
        />
    )
}

export default AboutSliders