import { useState } from "react"
import axios from "axios"

import { BLOGS_API, SERVICES_API } from "../../../Utilities/APIs"
import Form from "../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import Modal from "../../Partials/Elements/Modal/Modal"
import MediaGallery from "../../Partials/Layouts/MediaGallery/MediaGallery"
import { useEffect } from "react"


function UpdateBlog({ targetID, setShowUpdateForm, setShowModal, triggerFetch }) {

    const [showSecondModal, setShowSecondModal] = useState(false);
    const [showMediaGallery, setShowMediaGallery] = useState(false);


    const [service, setService] = useState('')
    const [name, setName] = useState('')
    const [tag, setTag] = useState('')
    const [image, setImage] = useState('')
    const [link, setLink] = useState('')
    const [publishDate, setPublishDate] = useState('')
    const [precedence, setPrecedence] = useState('')

    const [services, setServices] = useState(null)


    useEffect(() => {

        async function fetchAndSetServices() {
            const { data } = await axios.get(SERVICES_API)
            setServices(data)
        }
        fetchAndSetServices()


    }, [])

    useEffect(() => {

        async function fetchAndSetBlogInfo() {

            const { data } = await axios.get(BLOGS_API + 'getSingleBlog/' + targetID)
            const pd = new Date(data.publishDate).toISOString().slice(0, 10)
            setService(data.service._id);
            setName(data.name);
            setTag(data.tag);
            setLink(data.link);
            setImage(data.image);
            setPublishDate(pd);
            setPrecedence(data.precedence);;

        }
        fetchAndSetBlogInfo()
    }, [targetID])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('service', service)
        itemData.append('name', name)
        itemData.append('tag', tag)
        itemData.append('link', link)
        itemData.append('image', image)
        itemData.append('publishDate', publishDate)
        itemData.append('precedence', precedence)


        const response = await axios.patch(BLOGS_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);

            triggerFetch()
        }
    }


    function handleClose() {
        setShowSecondModal(false)
        setShowMediaGallery(false)
    }

    return (
        <>



            <Form onSubmit={handleSubmit} hasImage>
                <div className={`nav_content active`}>
                    <div className="input_group">
                        <label className='input_field_label'>
                            Service
                        </label>
                        <select
                            value={service}
                            onChange={(e) => setService(e.target.value)}
                            className="input_field"
                            placeholder="propertyCategory"
                        >
                            <option value="0" hidden>Select Service</option>
                            {services?.map((service => (
                                <option value={service._id} key={service._id}>
                                    {service?.name}
                                </option>
                            )))}
                        </select>
                    </div>
                    <ShortTextInput
                        label={`Name`}
                        value={name}
                        placeholder={`Enter Name`}
                        setState={setName}
                    />
                    <ShortTextInput
                        label={`News Portal`}
                        value={tag}
                        placeholder={`Enter News Portal`}
                        setState={setTag}
                    />
                    <ShortTextInput
                        label={`Link`}
                        value={link}
                        placeholder={`Enter Link`}
                        setState={setLink}
                    />

                    <>
                        <h1 className="input_field_label">Publish Date</h1>
                        <input
                            className="input_field"
                            type="date"
                            value={publishDate}
                            onChange={(e) => setPublishDate(e.target.value)}
                        />
                    </>
                    <ImageInput
                        fieldId='1'
                        state={image}
                        setState={setImage}
                        allowUpdateImage
                    >
                        Upload Card Image
                    </ImageInput>
                    <ShortTextInput
                        label={`Precedence`}
                        value={precedence}
                        placeholder={`Enter Precedence`}
                        setState={setPrecedence}
                    />

                </div>

                <FormSubmitButton text='Update Blog' />

            </Form>

            {showSecondModal &&
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={
                        `Media gallery`
                    }
                >

                    {showMediaGallery &&
                        <MediaGallery
                            initialScreen='blog'
                        />
                    }
                </Modal>
            }
        </>
    )
}

export default UpdateBlog