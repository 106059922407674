import { useState } from "react"
import axios from "axios"
import Form from "../../../Partials/Layouts/Forms/Form"
import {  MAGAZINE_SLIDERS_API, SERVICES_API } from "../../../../Utilities/APIs"
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput"
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption"
import { useEffect } from "react"


function CreateMagazineSlider({

    employee, setShowCreateForm, setShowModal, triggerFetch

}) {
    const [service, setService] = useState('')
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [image, setImage] = useState('')
    const [link, setLink] = useState('')
    const [buttonText1, setButtonText1] = useState('')
    const [buttonText2, setButtonText2] = useState('')

    const [services, setServices] = useState(null);

    useEffect(() => {
        async function fetchAndSetServices() {
            const { data } = await axios.get(SERVICES_API);
            setServices(data);
        }
        fetchAndSetServices();
    }, []);

    async function handleSubmit(e) {

        e.preventDefault()

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }
        const itemData = new FormData()
        itemData.append('service', service)
        itemData.append('name', name)
        itemData.append('description', description)
        itemData.append('image', image)
        itemData.append('link', link)
        itemData.append('buttonText1', buttonText1)
        itemData.append('buttonText2', buttonText2)

        const response = await axios.post(MAGAZINE_SLIDERS_API, itemData)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
             <SelectInput
                value={service}
                setState={setService}
            >
                <SelectOption  optionValue='' optionText="Select Service"/>
                {services?.map((service => (
                    <SelectOption optionValue={service._id} optionText={service?.name} />
                )))}
            </SelectInput>

            <ShortTextInput
                label={`Name`}
                value={name}
                placeholder={`Enter Name`}
                setState={setName}
            />
            <ShortTextInput
                label={`Description`}
                value={description}
                placeholder={`Enter Description`}
                setState={setDescription}
            />
            <ImageInput
                fieldId='1'
                state={image}
                setState={setImage}
                allowCreateImage
            >
                Upload Card Image
            </ImageInput>
            <ShortTextInput
                label={`Link`}
                value={link}
                placeholder={`Enter Link`}
                setState={setLink}
            />
            <ShortTextInput
                label={`Button Text One`}
                value={buttonText1}
                placeholder={`Enter Button Text One`}
                setState={setButtonText1}
            />
            <ShortTextInput
                label={`Button Text Two`}
                value={buttonText2}
                placeholder={`Enter Button Text Two`}
                setState={setButtonText2}
            />

            <FormSubmitButton text='Create Magazine Slider' />
        </Form>
    )
}

export default CreateMagazineSlider