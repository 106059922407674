import { useState, useEffect } from "react"
import axios from "axios"
import { EXHIBITIONS_API, IMAGE_URL } from "../../../Utilities/APIs"

function ViewExhibition({

    //common props
    targetID, employee

}) {

    const [exhibitionInfo, setExhibitionInfo] = useState(null)

    useEffect(() => {

        async function fetchAndSetExhibitionInfo() {

            const { data } = await axios.get(EXHIBITIONS_API + targetID)
            setExhibitionInfo(data);
        }
        fetchAndSetExhibitionInfo()
    }, [targetID])

    return (
        <div className="crud_view_content">

            {exhibitionInfo &&
                <>

                    {/* <h1>Service</h1>
                    <p>{exhibitionInfo?.service}</p> */}

                    <h1>Title</h1>
                    <p>{exhibitionInfo.title}</p>

                    <h1>Description</h1>
                    <p>{exhibitionInfo.description}</p>
                   
                    <h1>Date</h1>
                    <p>{new Date(exhibitionInfo?.date).toDateString()}</p>

                    <h1>Website Link </h1>
                    <p>{exhibitionInfo.websiteLink}</p>

                    <h1>Image</h1>
                    <img src={IMAGE_URL + exhibitionInfo.image} alt="" />

                    <h1>Location</h1>
                    <p>{exhibitionInfo.location}</p>

                </>
            }
        </div>
    )
}

export default ViewExhibition