import { useState, useEffect } from "react"
import axios from "axios"
import { IMAGE_URL, SERVICES_API } from "../../../Utilities/APIs"

function ViewBook({

    //common props
    targetID, employee

}) {

    const [bookInfo, setBookInfo] = useState(null)

    useEffect(() => {

        async function fetchAndSetBookInfo() {

            const { data } = await axios.get(SERVICES_API + 'getSingleBook/' + targetID)
            setBookInfo(data);
        }
        fetchAndSetBookInfo()
    }, [targetID])

    return (
        <div className="crud_view_content">

            {bookInfo &&
                <>
                    <h1>Name</h1>
                    <p>{bookInfo.name}</p>

                    <h1>Description</h1>
                    <p>{bookInfo.description}</p>

                    <h1>Image</h1>
                    <img src={IMAGE_URL + bookInfo.image} alt="" />

                    <h1>Pdf </h1>
                    <p>{bookInfo.pdf}</p>



                </>
            }
        </div>
    )
}

export default ViewBook