import { useEffect, useState } from "react"
import axios from "axios"

import { ARTICLES_API, FEATURED_ARTICLES_API } from "../../../../Utilities/APIs"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import Form from "../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"


function UpdateFeaturedArticle({

    targetID, employee, setShowUpdateForm, setShowModal, triggerFetch

}) {

    const [article, setArticle] = useState('')
    const [precedence, setPrecedence] = useState('')

    const [articles, setArticles] = useState(null)

    useEffect(() => {
        const config = {
            headers: {
                Authorization: `Bearer ${employee.token}`
            }
        }

        async function fetchAndSetFeaturedArticle() {

            const { data } = await axios.get(FEATURED_ARTICLES_API + targetID, config)
            setArticle(data.article._id);
            setPrecedence(data.precedence);
        }
        fetchAndSetFeaturedArticle()
    }, [targetID ,employee.token])

    useEffect(() => {

        async function fetchAndSetArticles() {
            const { data } = await axios.get(ARTICLES_API)
            const filterData = data.filter(d => d.hasService === false)
            setArticles(filterData)
        }
        fetchAndSetArticles()


    }, [])


    async function handleSubmit(e) {

        e.preventDefault()

        const config = {
            headers: {
                Authorization: `Bearer ${employee.token}`
            }
        }
        const itemData = {article, precedence}



        const response = await axios.patch(FEATURED_ARTICLES_API + targetID, itemData, config)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <div className="input_group">
                <label className='input_field_label'>
                    Article
                </label>
                <select
                    value={article}
                    onChange={(e) => setArticle(e.target.value)}
                    className="input_field"
                    placeholder="propertyCategory"
                >
                    {articles?.map((article => (
                        <option value={article._id} key={article._id}>
                            {article?.name}
                        </option>
                    )))}
                </select>
            </div>
            <ShortTextInput
                label={`Precedence`}
                value={precedence}
                placeholder={`Enter Precedence`}
                setState={setPrecedence}
            />


            <FormSubmitButton text='Update Featured Article' />
        </Form>
    )
}

export default UpdateFeaturedArticle