import NCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/NCRUD/NCRUD'
import {  IMAGE_GALLERY_TAGS_API } from '../../../Utilities/APIs'

function ImageGalleryTags() {
  return (
    <NCRUD
    // common props
    api={IMAGE_GALLERY_TAGS_API}
    screenTopicSingular='Image Gallery Tag'
    screenTopicPlural='Image Gallery Tags'

    // crud header props
    showTotalInHeading

  
    
    // crud props
    allowCreate
    allowUpdate
    allowDelete

  />
  )
}

export default ImageGalleryTags