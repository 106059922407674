import axios from "axios"
import { RiAddCircleFill } from "react-icons/ri"
import { API_URL } from "../../../../../../Utilities/APIs"

function AddNewMedia({triggerFetch, selectedCollection}) {

    async function handleAdd (file) {
        const formData = new FormData()

        formData.append('media', file)
        formData.append('mediaCollection', selectedCollection)

        await axios.post(API_URL + 'media/', formData)
        triggerFetch()
    }
    return (
        <div className="add_media">
            <input
                type="file"
                id="addMedia"
                onChange={e => handleAdd(e.target.files[0])}
            />

            <label
                htmlFor='addMedia'
                className='list_card add_lists'
            >
                <div className="icon">
                    <RiAddCircleFill />
                </div>
                <span>Add Image</span>
            </label>
        </div>
    )
}

export default AddNewMedia