import { useState, useEffect } from "react"
import axios from "axios"

import {  FEATURED_SUCCESS_STORIES_API } from "../../../../Utilities/APIs"

function ViewFeaturedSuccessStory({

    //common props
    targetID, employee

}) {

    const [featuredSuccessStory, setFeaturedSuccessStory] = useState(null)

    useEffect(() => {
        const config = {
            headers: {
                Authorization: `Bearer ${employee.token}`
            }
        }

        async function fetchAndSetFeaturedSuccessStory() {

            const { data } = await axios.get(FEATURED_SUCCESS_STORIES_API + targetID ,config)
            setFeaturedSuccessStory(data);
        }
        fetchAndSetFeaturedSuccessStory()
    }, [targetID ,employee.token])

    return (
        <div className="crud_view_content">

            {featuredSuccessStory &&
                <>
                    <h1>Name</h1>
                    <p>{featuredSuccessStory.successStory.name}</p>

                    <h1>Precedence</h1>
                    <p>{featuredSuccessStory.precedence}</p>

                </>
            }
        </div>
    )
}

export default ViewFeaturedSuccessStory