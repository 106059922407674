import { useState } from "react"
import axios from "axios"
import Form from "../../../Partials/Layouts/Forms/Form"
import { HOME_SLIDERS_API } from "../../../../Utilities/APIs"
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import { useEffect } from "react"


function UpdateHeroSlider({

    targetID, employee, setShowUpdateForm, setShowModal, triggerFetch

}) {
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [image, setImage] = useState('')
    const [buttonText1, setButtonText1] = useState('')
    const [buttonText2, setButtonText2] = useState('')
    const [service1, setService1] = useState('')
    const [service2, setService2] = useState('')
    const [service3, setService3] = useState('')

    useEffect(() => {

        async function fetchAndSetSliderInfo() {

            const { data } = await axios.get(HOME_SLIDERS_API + targetID)
            setTitle(data.title);
            setDescription(data.description);
            setImage(data.image);
            setButtonText1(data.buttonText1);
            setButtonText2(data.buttonText2);
            setService1(data.service1);
            setService2(data.service2);
            setService3(data.service3);
        }
        fetchAndSetSliderInfo()
    }, [targetID])

    async function handleSubmit(e) {

        e.preventDefault()

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }
        const itemData = new FormData()

        itemData.append('title', title)
        itemData.append('description', description)
        itemData.append('image', image)
        itemData.append('buttonText1', buttonText1)
        itemData.append('buttonText2', buttonText2)
        itemData.append('service1', service1)
        itemData.append('service2', service2)
        itemData.append('service3', service3)

        const response = await axios.patch(HOME_SLIDERS_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>

            <ShortTextInput
                label={`Title`}
                value={title}
                placeholder={`Enter Title`}
                setState={setTitle}
            />
            <ShortTextInput
                label={`Description`}
                value={description}
                placeholder={`Enter Description`}
                setState={setDescription}
            />
            <ImageInput
                fieldId='1'
                state={image}
                setState={setImage}
                allowUpdateImage
            >
                Upload Card Image
            </ImageInput>
            <ShortTextInput
                label={`Button Text One`}
                value={buttonText1}
                placeholder={`Enter Button Text One`}
                setState={setButtonText1}
            />
            <ShortTextInput
                label={`Button Text Two`}
                value={buttonText2}
                placeholder={`Enter Button Text Two`}
                setState={setButtonText2}
            />
            <ShortTextInput
                label={`Service One`}
                value={service1}
                placeholder={`Enter Service One`}
                setState={setService1}
            />
            <ShortTextInput
                label={`Service Two`}
                value={service2}
                placeholder={`Enter Service Two`}
                setState={setService2}
            />
            <ShortTextInput
                label={`Service Three`}
                value={service3}
                placeholder={`Enter Service Three`}
                setState={setService3}
            />

            <FormSubmitButton text='Update Hero Slider' />
        </Form>
    )
}

export default UpdateHeroSlider