import { RiContactsBook2Line } from "react-icons/ri"
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard"
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList"

function SettingsScreen() {
    return (
        <NavCardList numOfCards={'four'}>
            {/* <NavCard cardName={'App Settings'} navCardLink={'/appSettings'} ><RiSettings2Fill /></NavCard> */}
            <NavCard cardName={'Contacts'} navCardLink={'/contacts'} ><RiContactsBook2Line /></NavCard>
            {/* 
            <NavCard cardName={'Featured Articles'} navCardLink={'/featuredArticles'} ><RiMessageLine /></NavCard>
            <NavCard cardName={'Featured Success Stories'} navCardLink={'/featuredSuccessStories'} ><RiChat1Line /></NavCard> */}

        </NavCardList>
    )
}

export default SettingsScreen