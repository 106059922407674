import { useState, useEffect } from "react"
import axios from "axios"
import { ABOUT_WRITE_UPS_API } from "../../../../Utilities/APIs"

function ViewAboutWriteUp({

    //common props
    targetID, employee

}) {

    const [aboutWriteUp, setAboutWriteUp] = useState(null)

    useEffect(() => {

        async function fetchAndSetAboutWriteUp() {

            const { data } = await axios.get(ABOUT_WRITE_UPS_API + targetID)
            setAboutWriteUp(data);
        }
        fetchAndSetAboutWriteUp()
    }, [targetID])

    return (
        <div className="crud_view_content">

            {aboutWriteUp &&
                <>
                    <h1>Title</h1>
                    <p>{aboutWriteUp.title}</p>

                    <h1>Description</h1>
                    <div
                        dangerouslySetInnerHTML={{ __html: aboutWriteUp.description }}
                    />


                </>
            }
        </div>
    )
}

export default ViewAboutWriteUp