import { useEffect, useState } from "react";
import axios from "axios";
import Form from "../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { SERVICES_API } from "../../../Utilities/APIs";
import SwitchInput from "../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput";

function UpdateService({
  targetID,
  employee,
  setShowUpdateForm,
  setShowModal,
  triggerFetch,
}) {

  const [name, setName] = useState("");
  const [slug, setSlug] = useState('')
  const [shortName, setShortName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState('')
  const [image, setImage] = useState("");
  const [videoThumbnail, setVideoThumbnail] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [colorCode, setColorCode] = useState("");
  const [textColor, setTextColor] = useState("");
  const [isImpact, setIsImpact] = useState(false);
  const [isExhibition, setIsExhibition] = useState(false);
  const [exhibitionTitle, setExhibitionTitle] = useState('')
  const [isProgram, setIsProgram] = useState(false);
  const [programTitle, setProgramTitle] = useState('')
  // const [isBlog, setIsBlog] = useState(false);
  // const [blogTitle, setBlogTitle] = useState('')
  // const [blogSubTitle, setBlogSubTitle] = useState('')
  const [isMagazine, setIsMagazine] = useState(false)
  const [isArticle, setIsArticle] = useState(false)
  const [articleTitle, setArticleTitle] = useState('')
  const [articleSubTitle, setArticleSubTitle] = useState('')
  const [isSuccessStory, setIsSuccessStory] = useState(false)
  const [successStoryTitle, setSuccessStoryTitle] = useState('')
  const [successStorySubTitle, setSuccessStorySubTitle] = useState('')
  const [isEvent, setIsEvent] = useState(false)
  const [eventTitle, setEventTitle] = useState('')
  const [impectTitle, setImpectTitle] = useState("")
  const [subServiceTitle, setSubServiceTitle] = useState("")
  const [precedence, setPrecedence] = useState("");

  // const tc = textColor?.toLocaleLowerCase()

  useEffect(() => {
    async function fetchAndSetServiceInfo() {
      const { data } = await axios.get(SERVICES_API + targetID);
      setName(data.name);
      setSlug(data.slug);
      setShortName(data.shortName);
      setTitle(data.title);
      setDescription(data.description);
      setShortDescription(data.shortDescription);
      setImage(data.image);
      setVideoThumbnail(data.videoThumbnail);
      setVideoLink(data.videoLink);
      setColorCode(data.colorCode);
      setTextColor(data.textColor);
      setIsImpact(data.isImpact);
      setIsExhibition(data.isExhibition);
      setExhibitionTitle(data.exhibitionTitle);
      setIsProgram(data.isProgram);
      setProgramTitle(data.programTitle);
      // setIsBlog(data.isBlog);
      // setBlogTitle(data.blogTitle);
      // setBlogSubTitle(data.blogSubTitle);
      // setIsBlog(data.isBlog);
      setIsMagazine(data.isMagazine);
      setIsArticle(data.isArticle);
      setArticleTitle(data.articleTitle);
      setArticleSubTitle(data.articleSubTitle);
      setIsSuccessStory(data.isSuccessStory);
      setSuccessStoryTitle(data.successStoryTitle);
      setSuccessStorySubTitle(data.successStorySubTitle);
      setIsEvent(data.isEvent);
      setEventTitle(data.eventTitle);
      setImpectTitle(data.impectTitle);
      setSubServiceTitle(data.subServiceTitle);
      setPrecedence(data.precedence);
    }
    fetchAndSetServiceInfo();
  }, [targetID]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("name", name);
    itemData.append("slug", slug);
    itemData.append("shortName", shortName);
    itemData.append("title", title);
    itemData.append("description", description);
    itemData.append("shortDescription", shortDescription);
    itemData.append("image", image);
    itemData.append("videoThumbnail", videoThumbnail);
    itemData.append("videoLink", videoLink);
    itemData.append("colorCode", colorCode);
    itemData.append("textColor", textColor);
    itemData.append("isImpact", isImpact);
    itemData.append("isExhibition", isExhibition);
    itemData.append("exhibitionTitle", exhibitionTitle);
    itemData.append("isProgram", isProgram);
    itemData.append("programTitle", programTitle);
    // itemData.append("isBlog", isBlog);
    // itemData.append("blogTitle", blogTitle);
    // itemData.append("blogSubTitle", blogSubTitle);
    itemData.append("isMagazine", isMagazine);
    itemData.append("isArticle", isArticle);
    itemData.append("articleTitle", articleTitle);
    itemData.append("articleSubTitle", articleSubTitle);
    itemData.append("isSuccessStory", isSuccessStory);
    itemData.append("successStoryTitle", successStoryTitle);
    itemData.append("successStorySubTitle", successStorySubTitle);
    itemData.append("isEvent", isEvent);
    itemData.append("eventTitle", eventTitle);
    itemData.append("impectTitle", impectTitle);
    itemData.append("subServiceTitle", subServiceTitle);
    itemData.append("precedence", precedence);

    const response = await axios.patch(SERVICES_API + targetID, itemData);

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (

    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Name`}
        value={name}
        placeholder={`Enter Name`}
        setState={setName}
      />
          <ShortTextInput
        label={`Slug`}
        value={slug}
        placeholder={`Enter Slug`}
        setState={setSlug}
      />
      <ShortTextInput
        label={`Short Name`}
        value={shortName}
        placeholder={`Enter Short Name`}
        setState={setShortName}
      />
      <ShortTextInput
        label={`Title`}
        value={title}
        placeholder={`Enter Title`}
        setState={setTitle}
      />

      <ShortTextInput
        label={`Description`}
        value={description}
        placeholder={`Enter Description`}
        setState={setDescription}
      />
      <ShortTextInput
        label={`Short Description`}
        value={shortDescription}
        placeholder={`Enter Short Description`}
        setState={setShortDescription}
      />
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowUpdateImage
      >
        Upload Card Image
      </ImageInput>
      <ImageInput
        fieldId="2"
        state={videoThumbnail}
        setState={setVideoThumbnail}
        allowUpdateImage
      >
        Upload Thumbnail
      </ImageInput>

      <ShortTextInput
        label={`Video Link`}
        value={videoLink}
        placeholder={`Enter Video Link`}
        setState={setVideoLink}
      />
      <ShortTextInput
        label={`Color Code`}
        value={colorCode}
        placeholder={`Enter Color Code`}
        setState={setColorCode}
      />
      <label className='input_field_label'>
        Text Color
      </label>
      <select
        value={textColor}
        onChange={(e) => setTextColor(e.target.value)}
        className="input_field"
        placeholder="Enter Text Color"
      >
        <option value="0" hidden>Select Text Color</option>
        <option value="black">Black</option>
        <option value="white">White</option>
      </select>
      <ShortTextInput
        label={`Sub Service Title`}
        value={subServiceTitle}
        placeholder={`Enter Sub Service Title`}
        setState={setSubServiceTitle}
      />
      <SwitchInput
        label={`Is Visible Impact`}
        toggleSwitch={() => setIsImpact((prevState) => !prevState)}
        checked={isImpact}
      />
      {
        isImpact &&
        <ShortTextInput
          label={`Impect Title`}
          value={impectTitle}
          placeholder={`Enter Impect Title`}
          setState={setImpectTitle}
        />
      }
      <SwitchInput
        label={`Is Exhibition`}
        toggleSwitch={() => setIsExhibition((prevState) => !prevState)}
        checked={isExhibition}
      />
      {
        isExhibition &&
        <ShortTextInput
          label={`Exhibition Section Title`}
          value={exhibitionTitle}
          placeholder={`Enter Exhibition Section Title`}
          setState={setExhibitionTitle}
        />
      }
      <SwitchInput
        label={`Is Program`}
        toggleSwitch={() => setIsProgram((prevState) => !prevState)}
        checked={isProgram}
      />
      {
        isProgram &&
        <ShortTextInput
          label={`Program Section Title`}
          value={programTitle}
          placeholder={`Enter Program Section Title`}
          setState={setProgramTitle}
        />
      }
      {/* <SwitchInput
        label={`Is Blog`}
        toggleSwitch={() => setIsBlog((prevState) => !prevState)}
        checked={isBlog}
      />
      {
        isBlog &&
        <>
          <ShortTextInput
            label={`Blog Title`}
            value={blogTitle}
            placeholder={`Enter Blog Title`}
            setState={setBlogTitle}
          />
          <ShortTextInput
            label={`Blog Subtitle`}
            value={blogSubTitle}
            placeholder={`Enter Blog Subtitle`}
            setState={setBlogSubTitle}
          />
        </>
      } */}
      <SwitchInput
        label={`Is Magazine`}
        toggleSwitch={() => setIsMagazine((prevState) => !prevState)}
        checked={isMagazine}
      />
      <SwitchInput
        label={`Is Article`}
        toggleSwitch={() => setIsArticle((prevState) => !prevState)}
        checked={isArticle}
      />
      {
        isArticle &&
        <>
          <ShortTextInput
            label={`Article Section Title`}
            value={articleTitle}
            placeholder={`Enter Article Section Title`}
            setState={setArticleTitle}
          />
          <ShortTextInput
            label={`Article Section Subtitle`}
            value={articleSubTitle}
            placeholder={`Enter Article Section Subtitle`}
            setState={setArticleSubTitle}
          />
        </>
      }
      <SwitchInput
        label={`Is Success Story`}
        toggleSwitch={() => setIsSuccessStory((prevState) => !prevState)}
        checked={isSuccessStory}
      />
      {
        isSuccessStory &&
        <>
          <ShortTextInput
            label={`Success Story Section Title`}
            value={successStoryTitle}
            placeholder={`Enter Success Story Section Title`}
            setState={setSuccessStoryTitle}
          />
          <ShortTextInput
            label={`Success Story Section Subtitle`}
            value={successStorySubTitle}
            placeholder={`Enter Success Story Section Subtitle`}
            setState={setSuccessStorySubTitle}
          />
        </>
      }
      <SwitchInput
        label={`Is Event`}
        toggleSwitch={() => setIsEvent((prevState) => !prevState)}
        checked={isEvent}
      />
      {
        isEvent &&
        <ShortTextInput
          label={`Event Title`}
          value={eventTitle}
          placeholder={`Enter Event Title`}
          setState={setEventTitle}
        />
      }
      <ShortTextInput
        label={`Precedence`}
        value={precedence}
        placeholder={`Enter Precedence`}
        setState={setPrecedence}
      />


      <FormSubmitButton text="Create Service" />
    </Form>
  );
}

export default UpdateService;
