import React from 'react'
import { TESTIMONIALS_API } from '../../../Utilities/APIs'
import DLINPTCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/DLINPTCRUD/DLINPTCRUD'

function Testimonials() {
    return (
        <DLINPTCRUD
            // common props
            api={TESTIMONIALS_API}
            screenTopicSingular='Testimonial'
            screenTopicPlural='Testimonials'


            // crud header props
            showTotalInHeading


            // crud props
            allowCreate
            allowUpdate
            allowDelete


            //extra field props
            textDBField="precedence"
            textName="Precedence"
            
            longTextDBField='designation'
            longTextName='Designation'
        />
    )
}

export default Testimonials