import { ABOUT_TOP_SECTION_MAIN_TEXTS_API } from "../../../Utilities/APIs"
import NCRUD from "../../Partials/Layouts/CRUDs/CRUDLayouts/NCRUD/NCRUD"

function AboutTopMainTexts() {
    return (
        <NCRUD
            // common props
            api={ABOUT_TOP_SECTION_MAIN_TEXTS_API}
            screenTopicSingular='About Top Main Text'
            screenTopicPlural='About Top Main Texts'


            // crud header props
            showTotalInHeading

            // crud props
            // allowCreate
            allowUpdate
            // allowDelete

        />
    )
}

export default AboutTopMainTexts