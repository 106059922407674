import { useEffect, useState } from "react"
import axios from "axios"

import {  FEATURED_SUCCESS_STORIES_API, SUCCESS_STORIES_API } from "../../../../Utilities/APIs"
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput"
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import Form from "../../../Partials/Layouts/Forms/Form"


function CreateFeaturedSuccessStory({

    employee, setShowCreateForm, setShowModal, triggerFetch

}) {

    const [successStory, setSuccessStory] = useState('')
    const [precedence, setPrecedence] = useState('')

    const [successStories, seSuccessStories] = useState(null)

  

    useEffect(() => {

        async function fetchAndSetSuccessStory() {
            const { data } = await axios.get(SUCCESS_STORIES_API)
            seSuccessStories(data)
        }
        fetchAndSetSuccessStory()


    }, [])

    function precedenceSelectOptions() {
        const rows = [];
        for (let i = 1; i <= 6; i++) {
            rows.push(<SelectOption key={i} optionText={i} optionValue={i} />);
        }
        return rows;
    }

    async function handleSubmit(e) {

        e.preventDefault()

         const config = {
            headers: {
                Authorization: `Bearer ${employee.token}`
            }
        }
        const itemData = new FormData()

        itemData.append('successStory', successStory)
        itemData.append('precedence', precedence)



        const response = await axios.post(FEATURED_SUCCESS_STORIES_API, itemData ,config)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <div className="input_group">
                <label className='input_field_label'>
                   Success Story
                </label>
                <select
                    value={successStory}
                    onChange={(e) => setSuccessStory(e.target.value)}
                    className="input_field"
                    placeholder="propertyCategory"
                >
                    <option value="0" hidden>Select Success Story</option>
                    {successStories?.map((successStory => (
                        <option value={successStory._id} key={successStory._id}>
                            {successStory?.name}
                        </option>
                    )))}
                </select>
            </div>
            <SelectInput value={precedence} setState={setPrecedence}>
                <SelectOption optionValue={0} optionText="Select Precedence" />
                {precedenceSelectOptions()}
            </SelectInput>


            <FormSubmitButton text='Create Featured Article' />
        </Form>
    )
}

export default CreateFeaturedSuccessStory