import { RiArticleLine, RiCollageLine, RiHardDriveLine, RiServiceLine } from "react-icons/ri"
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard"
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList"

function ServiceScreen() {
    return (
        <NavCardList numOfCards={'four'}>
            <NavCard cardName={'Services'} navCardLink={'/services'} ><RiServiceLine /></NavCard>
            <NavCard cardName={'Sub Services'} navCardLink={'/subServices'} ><RiHardDriveLine /></NavCard>
            <NavCard cardName={'Articles'} navCardLink={'/articles'} ><RiArticleLine /></NavCard>
             <NavCard cardName={'Service Impacts'} navCardLink={'/impacts'} ><RiCollageLine /></NavCard>


        </NavCardList>
    )
}

export default ServiceScreen