import LTCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/LTCRUD/LTCRUD'
import { ABOUT_IMPACTS_API } from '../../../Utilities/APIs'

function AboutImpacts() {
    return (
        <LTCRUD
            // common props
            api={ABOUT_IMPACTS_API}
            screenTopicSingular="About Impact"
            screenTopicPlural="About Impacts"

            // crud header props
            showTotalInHeading

            // crud props
            // allowCreate
            allowUpdate
            // allowDelete

            //extra field props
            textDBField="mainText"
            textName="Main Text"

            longTextDBField="subText"
            longTextName="Sub Text"
        />
    )
}

export default AboutImpacts