import React from "react";
import CRUDBoilerPlate from "../../CRUDBoilerPlate/CRUDBoilerPlate";

function DLINPTSCRUD({
  // common props
  api,
  screenTopicSingular,
  screenTopicPlural,

  // crud header props
  showTotalInHeading,

  // crud props
  allowCreate,
  allowUpdate,
  allowDelete,


  //extra field props
  textDBField,
  textName,
  longTextDBField, longTextName,
  // select props
  selectApi,
  selectType,
  selectDBField,
  selectName,
}) {
  return (
    <CRUDBoilerPlate
      // common props
      api={api}
      screenTopicSingular={screenTopicSingular}
      screenTopicPlural={screenTopicPlural}
      // crud header props
      showTotalInHeading={showTotalInHeading}
      // crud props
      allowCreate={allowCreate}
      allowUpdate={allowUpdate}
      allowDelete={allowDelete}
      // field props
      hasName
      hasDescription
      hasImage
      hasText
      textDBField={textDBField}
      textName={textName}
      hasLongText
      longTextDBField={longTextDBField}
      longTextName={longTextName}
      // select props
      hasSelect
      selectApi={selectApi}
      selectType={selectType}
      selectDBField={selectDBField}
      selectName={selectName}
    />
  );
}

export default DLINPTSCRUD;
