import { useState, useEffect } from "react"
import axios from "axios"
import { IMAGE_URL, SERVICES_API } from "../../../Utilities/APIs"

function ViewService({

    //common props
    targetID, employee

}) {

    const [serviceInfo, setServiceInfo] = useState(null)

    useEffect(() => {

        async function fetchAndSetServiceInfo() {

            const { data } = await axios.get(SERVICES_API + targetID)
            setServiceInfo(data);
        }
        fetchAndSetServiceInfo()
    }, [targetID])

    return (
        <div className="crud_view_content">

            {serviceInfo &&
                <>
                    <h1>Name</h1>
                    <p>{serviceInfo.name}</p>

                    <h1>Slug</h1>
                    <p>{serviceInfo?.slug}</p>

                    <h1>Short Name</h1>
                    <p>{serviceInfo.shortName}</p>

                    <h1>Title</h1>
                    <p>{serviceInfo.title}</p>

                    <h1>Description</h1>
                    <p>{serviceInfo.description}</p>

                    <h1>Short Description</h1>
                    <p>{serviceInfo.shortDescription}</p>

                    <h1>Image</h1>
                    <img src={IMAGE_URL + serviceInfo.image} alt="" />

                    <h1>Thumbnail </h1>
                    <img src={IMAGE_URL + serviceInfo.videoThumbnail} alt="" />

                    <h1>Video Link </h1>
                    <p>{serviceInfo.videoLink}</p>

                    <h1>Color Code</h1>
                    <p>{serviceInfo.colorCode}</p>

                    <h1>Text Code</h1>
                    <p>{serviceInfo.textColor}</p>

                    <h1>Is Impact</h1>
                    <p>{serviceInfo.isImpact === true ? 'True' : 'False'}</p>

                    <h1>Impact Section Title</h1>
                    <p>{serviceInfo.impectTitle}</p>

                    <h1>Is Exhibition</h1>
                    <p>{serviceInfo.isExhibition === true ? 'True' : 'False'}</p>

                    <h1>Exhibition Section Title</h1>
                    <p>{serviceInfo.exhibitionTitle}</p>

                    <h1>Is Program</h1>
                    <p>{serviceInfo.isProgram === true ? 'True' : 'False'}</p>

                    <h1>Program Section Title</h1>
                    <p>{serviceInfo.programTitle}</p>

                    <h1>Is Blog</h1>
                    <p>{serviceInfo.isBlog === true ? 'True' : 'False'}</p>

                    <h1>Blog Title</h1>
                    <p>{serviceInfo.blogTitle}</p>

                    <h1>Blog Subtitle</h1>
                    <p>{serviceInfo.blogSubTitle}</p>

                    <h1>Is Blog</h1>
                    <p>{serviceInfo.isBlog === true ? 'True' : 'False'}</p>

                    <h1>Is Event</h1>
                    <p>{serviceInfo.isEvent === true ? 'True' : 'False'}</p>

                    <h1> Event Title</h1>
                    <p>{serviceInfo.eventTitle}</p>

                    <h1>Is Magazine</h1>
                    <p>{serviceInfo.isMagazine === true ? 'True' : 'False'}</p>

                    <h1>Is Article</h1>
                    <p>{serviceInfo.isArticle === true ? 'True' : 'False'}</p>

                    <h1>Article Section Title</h1>
                    <p>{serviceInfo.articleTitle}</p>

                    <h1>Article Section Subtitle</h1>
                    <p>{serviceInfo.articleSubTitle}</p>

                    <h1>Is Success Story</h1>
                    <p>{serviceInfo.isSuccessStory === true ? 'True' : 'False'}</p>

                    <h1>Success Story Section Title</h1>
                    <p>{serviceInfo.successStoryTitle}</p>

                    <h1>Success Story Section Subitle</h1>
                    <p>{serviceInfo.successStorySubTitle}</p>

                    <h1>Precedence</h1>
                    <p>{serviceInfo.precedence}</p>
                </>
            }
        </div>
    )

}

export default ViewService