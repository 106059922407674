import { RiFacebookLine, RiMapPin2Line } from "react-icons/ri"
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard"
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList"

function FooterScreen() {
    return (
        <NavCardList numOfCards={'four'}>
            {/* <NavCard cardName={'Footer Heading'} navCardLink={'/footerTexts'} ><RiChat1Line /></NavCard> */}
            <NavCard cardName={'Social Links'} navCardLink={'/socialLinks'} ><RiFacebookLine /></NavCard>
            <NavCard cardName={'Footer Contact Infos'} navCardLink={'/contactInfos'} ><RiMapPin2Line /></NavCard>
        </NavCardList>
    )
}

export default FooterScreen