import { RiAddCircleFill } from 'react-icons/ri'
import MediaFolder from './MediaFolder/MediaFolder'
import { API_URL } from '../../../../../Utilities/APIs';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
// import MediaLists from '../MediaLists/MediaLists';
import Modal from '../../../Elements/Modal/Modal';
import CreateCollectionForm from './CreateCollectionForm/CreateCollectionForm';

function MediaFolderView({ screen, setScreen, setSelectedCollection }) {

    const [showThirdModal, setShowThirdModal] = useState(false);
    const [showCreateCollectionForm, setShowCreateCollectionForm] = useState(false);
    const [toggleFetch, setToggleFetch] = useState(false)
    const [folders, setFolders] = useState(null);
    console.log(folders)

    useEffect(() => {
        if (screen === 'initial') {

            setFolders([
                {
                    _id: 1,
                    name: 'Blogs'
                },
                {
                    _id: 2,
                    name: 'Article'
                },

            ])
        }
        if (screen === 'blog') {
            async function getBlogCollections() {
                const { data } = await axios.get(API_URL + 'mediaCollections/')
                setFolders(data)
            }
            getBlogCollections()
        }
        if (screen === 'article') {
            async function getArticleCollections() {
                const { data } = await axios.get(API_URL + 'mediaCollections/')
                setFolders(data)
            }
            getArticleCollections()
        }
    }, [toggleFetch, screen]);

    function handleClose() {
        setShowThirdModal(false)
        setShowCreateCollectionForm(false)
    }

    function triggerFetch() {
        setToggleFetch(prevState => !prevState)
    }


    return (
        <>
            <div className='media_gallery'>

                <div className='create_btn_box'>
                    {/* <div>
                        {screen !== 'initial' &&
                            <button
                                className="btn_create_collection"
                                onClick={() => setScreen('initial')}
                            >
                                <RiArrowLeftCircleFill />
                                <span>gallery home</span>
                            </button>
                        }
                    </div> */}

                    <div>
                        <button
                            className="btn_create_collection fill_yellow"
                            onClick={() => {
                                setShowThirdModal(true)
                                setShowCreateCollectionForm(true)
                            }}
                        >
                            <RiAddCircleFill />
                            <span>Create Collection</span>
                        </button>
                    </div>
                </div>
                <div className='folder_collection'>
                    {folders?.map(folder => (
                        <MediaFolder
                            key={folder._id}
                            id={folder._id}
                            name={folder?.name}
                            screen={screen}
                            setScreen={setScreen}
                            setSelectedCollection={setSelectedCollection}
                        />
                    ))}
                </div>
            </div>

            {showThirdModal &&
                <Modal
                    handleClose={handleClose}
                    modalHeading={
                        `Add Collections`
                    }
                >

                    {showCreateCollectionForm &&
                        <CreateCollectionForm
                            triggerFetch={triggerFetch}
                            category={screen}
                            setShowThirdModal={setShowThirdModal}
                            setShowCreateCollectionForm={setShowCreateCollectionForm}
                        />
                    }
                </Modal>
            }
        </>
    )
}

export default MediaFolderView