import { useState } from "react"
import axios from "axios"
import Form from "../../../Partials/Layouts/Forms/Form"
import { HOME_PAGE_SECTION_TITLES_API } from "../../../../Utilities/APIs"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import { useEffect } from "react"


function UpdateHomePageSectionTitle({

    targetID, employee, setShowUpdateForm, setShowModal, triggerFetch

}) {
    const [workFeaturetitle, setWorkFeaturetitle] = useState('')
    const [serviceSectiontitle, setServiceSectiontitle] = useState('')
    const [serviceSectionSubtitle, setServiceSectionSubtitle] = useState('')
    const [testimonialSectiontitle, setTestimonialSectiontitle] = useState('')
    const [testimonialSectionSubtitle, setTestimonialSectionSubtitle] = useState('')
    const [successStorySectiontitle, setSuccessStorySectiontitle] = useState('')
    const [successStorySectionSubtitle, setSuccessStorySectionSubtitle] = useState('')
    const [mediaSectiontitle, setMediaSectiontitle] = useState('')
    const [mediaSectionSubtitle, setMediaSectionSubtitle] = useState('')


    useEffect(() => {

        async function fetchAndSethomePageSectionTitle() {

            const { data } = await axios.get(HOME_PAGE_SECTION_TITLES_API + targetID)
            setWorkFeaturetitle(data.workFeaturetitle);
            setServiceSectiontitle(data.serviceSectiontitle);
            setServiceSectionSubtitle(data.serviceSectionSubtitle);
            setTestimonialSectiontitle(data.testimonialSectiontitle);
            setTestimonialSectionSubtitle(data.testimonialSectionSubtitle);
            setSuccessStorySectiontitle(data.successStorySectiontitle);
            setSuccessStorySectionSubtitle(data.successStorySectionSubtitle);
            setMediaSectiontitle(data.mediaSectiontitle);
            setMediaSectionSubtitle(data.mediaSectionSubtitle);
        }
        fetchAndSethomePageSectionTitle()
    }, [targetID])

    async function handleSubmit(e) {

        e.preventDefault()

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }
        const itemData = {
            workFeaturetitle,
            serviceSectiontitle,
            serviceSectionSubtitle,
            testimonialSectiontitle,
            testimonialSectionSubtitle,
            successStorySectiontitle,
            successStorySectionSubtitle,
            mediaSectiontitle,
            mediaSectionSubtitle
        }


        const response = await axios.patch(HOME_PAGE_SECTION_TITLES_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>

            <ShortTextInput
                label={`Work Feature Title`}
                value={workFeaturetitle}
                placeholder={`Enter Work Feature Title`}
                setState={setWorkFeaturetitle}
            />
            <ShortTextInput
                label={`Service Section title`}
                value={serviceSectiontitle}
                placeholder={`Enter Service Section title`}
                setState={setServiceSectiontitle}
            />
            <ShortTextInput
                label={`Service Section Subtitle`}
                value={serviceSectionSubtitle}
                placeholder={`Enter Service Section Subtitle`}
                setState={setServiceSectionSubtitle}
            />
            <ShortTextInput
                label={`Testimonial Section title`}
                value={testimonialSectiontitle}
                placeholder={`Enter Testimonial Section title`}
                setState={setTestimonialSectiontitle}
            />
            <ShortTextInput
                label={`Testimonial Section Subtitle`}
                value={testimonialSectionSubtitle}
                placeholder={`Enter Testimonial Section Subtitle`}
                setState={setTestimonialSectionSubtitle}
            />
            <ShortTextInput
                label={`Success Story Section title`}
                value={successStorySectiontitle}
                placeholder={`Enter Success Story Section title`}
                setState={setSuccessStorySectiontitle}
            />
            <ShortTextInput
                label={`Success Story Section Subtitle`}
                value={successStorySectionSubtitle}
                placeholder={`Enter Success Story Section Subtitle`}
                setState={setSuccessStorySectionSubtitle}
            />
            <ShortTextInput
                label={`Media Section title`}
                value={mediaSectiontitle}
                placeholder={`Enter Media Section title`}
                setState={setMediaSectiontitle}
            />
            <ShortTextInput
                label={`Media Section Subtitle`}
                value={mediaSectionSubtitle}
                placeholder={`Enter Media Section Subtitle`}
                setState={setMediaSectionSubtitle}
            />

            <FormSubmitButton text='Create Home Page Section Title' />
        </Form>
    )
}

export default UpdateHomePageSectionTitle