import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../Redux/Features/Auth/authSlice'

import './App.css'

import NavBar from './Partials/Sections/NavBar/NavBar'
import SideBar from './Partials/Sections/SideBar/SideBar'
import Login from './Screens/AuthScreens/Login/Login'
import Dashboard from './Screens/Dashboard/Dashboard'
import InviteEmployees from './Screens/AuthScreens/InviteEmployees/InviteEmployees'
import EmployeesScreen from './Screens/AuthScreens/EmployeesScreen'
import Employees from './Screens/AuthScreens/Employees/Employees'
import AppSettings from './Screens/AppSettings/AppSettings'
import Register from './Screens/AuthScreens/Register/Register'
import SettingsScreen from './Screens/Settings/SettingsScreen'
import RecoverPassOne from './Screens/AuthScreens/RecoverPassOne'
import RecoverPassThree from './Screens/AuthScreens/RecoverPassThree'

import ContactInfosScreen from './Screens/ContactInfosScreen/ContactInfosScreen'
import UsersScreen from './Screens/UsersScreen/UsersScreen'

import Customers from './Screens/UsersScreen/Customers/Customers'
import EmployeeScreen from './Screens/AuthScreens/Employees/EmployeeScreen'
import Blogs from './Screens/Blogs/Blogs'
import SocialLinks from './Screens/SocialLinks/SocialLinks'

import FeaturedBlogs from './Screens/Blogs/FeaturedBlogs'
import WorkFeatures from './Screens/HomeScreen/WorkFeatures'
import Testimonials from './Screens/HomeScreen/Testimonials'
import AboutScreen from './Screens/AboutScreen/AboutScreen'
import AboutSliders from './Screens/AboutScreen/AboutSliders'
import TextSliders from './Screens/AboutScreen/TextSliders'
import Services from './Screens/Services/Services'
import Contacts from './Screens/Contacts/Contacts'
import Others from './Screens/Others/Others'
import SubServices from './Screens/Others/SubServices'
import Programs from './Screens/Others/Programs'
import ExpoWriteUp from './Screens/Others/ExpoWriteUp'
import Events from './Screens/Others/Events'
import Impacts from './Screens/Others/Impacts'
import AboutVideos from './Screens/HomeScreen/AboutVideos'
import Books from './Screens/Books/Books'
import SuccessStories from './Screens/SuccessStories/SuccessStories'
import Exhibitions from './Screens/Exhibitions/Exhibitions'
import Articles from './Screens/Articles/Articles'
import AboutImpacts from './Screens/AboutScreen/AboutImpacts'
import ServiceTestimonials from './Screens/Others/ServiceTestimonials'
import FeaturedArticles from './Screens/Articles/FeaturedArticles/FeaturedArticles'
import FeaturedSuccessStories from './Screens/SuccessStories/FeaturedSuccessStories/FeaturedSuccessStories'
import FooterTexts from './Screens/HomeScreen/FooterTexts'
import HomeVideos from './Screens/HomeScreen/HomeVideos'
import HeroSliders from './Screens/HomeScreen/Sliders/HeroSliders'
import Abouts from './Screens/AboutScreen/Abouts/Abouts'
import BookSliders from './Screens/Others/BookSliders/BookSliders'
import MagazineSliders from './Screens/Others/MagazineSliders/MagazineSliders'
import ServiceScreen from './Screens/ServiceScreen/ServiceScreen'
import FooterScreen from './Screens/FooterScreen/FooterScreen'
import AboutTopMainTexts from './Screens/AboutScreen/AboutTopMainTexts'
import AboutTopChangingTexts from './Screens/AboutScreen/AboutTopChangingTexts'
import HomePageSectionTitles from './Screens/HomeScreen/HomePageSectionTitles/HomePageSectionTitles'
import AboutWriteUps from './Screens/AboutScreen/AboutWriteUps/AboutWriteUps'
import AboutVodeo from './Screens/AboutScreen/AboutVodeo/AboutVodeo'
import TagsScreens from './Screens/TagsScreens/TagsScreens'
import ImageGalleryTags from './Screens/TagsScreens/ImageGalleryTags'
import ArticleTags from './Screens/TagsScreens/ArticleTags'
import SuccessStoryTags from './Screens/TagsScreens/SuccessStoryTags'
import ImageGalleries from './Screens/NewsMedia/ImageGalleries'


function App() {

  const { employee } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (err.response.status === 401) {
        dispatch(logout())
      }
      return Promise.reject(err);
    }
  );

  return (
    <section>
      <BrowserRouter>
        <NavBar />
        <main>
          {employee && <SideBar />}
          <section className='screen-container'>

            <Routes>

              <Route path='/login' element={!employee ? <Login /> : <Navigate to={'/'} />} />
              <Route path='/register/:token' element={!employee ? <Register /> : <Navigate to={'/'} />} />
              <Route path="/recover-pass-one" element={<RecoverPassOne />} />
              <Route path="/resetEmployeePassword/:token" element={<RecoverPassThree />} />

              <Route path='/employeeScreens' element={employee ? <EmployeesScreen /> : <Navigate to={'/login'} />} />
              <Route path='/employeeInvites' element={employee ? <InviteEmployees /> : <Navigate to={'/login'} />} />
              <Route path='/employeeScreen' element={employee ? <EmployeeScreen /> : <Navigate to={'/login'} />} />
              <Route path='/employees' element={employee ? <Employees /> : <Navigate to={'/login'} />} />
              <Route path='/customers' element={employee ? <Customers /> : <Navigate to={'/login'} />} />

              <Route path='/' element={employee ? <Dashboard /> : <Navigate to={'/login'} />} />
              <Route path='/homeSliders' element={employee ? <HeroSliders /> : <Navigate to={'/login'} />} />
              <Route path='/homePageSectionTitles' element={employee ? <HomePageSectionTitles /> : <Navigate to={'/login'} />} />
              <Route path='/homeVideos' element={employee ? <HomeVideos /> : <Navigate to={'/login'} />} />
              <Route path='/workFeatures' element={employee ? <WorkFeatures /> : <Navigate to={'/login'} />} />
              <Route path='/testimonials' element={employee ? <Testimonials /> : <Navigate to={'/login'} />} />
              <Route path='/serviceTestimonials' element={employee ? <ServiceTestimonials /> : <Navigate to={'/login'} />} />
              <Route path='/services' element={employee ? <Services /> : <Navigate to={'/login'} />} />
              <Route path='/successStories' element={employee ? <SuccessStories /> : <Navigate to={'/login'} />} />
              <Route path='/featuredSuccessStories' element={employee ? <FeaturedSuccessStories /> : <Navigate to={'/login'} />} />
              <Route path='/articles' element={employee ? <Articles /> : <Navigate to={'/login'} />} />
              <Route path='/featuredArticles' element={employee ? <FeaturedArticles /> : <Navigate to={'/login'} />} />
              <Route path='/aboutImpactTitles' element={employee ? <FooterTexts /> : <Navigate to={'/login'} />} />

              <Route path='/aboutScreen' element={employee ? <AboutScreen /> : <Navigate to={'/login'} />} />
              <Route path='/about' element={employee ? <Abouts /> : <Navigate to={'/login'} />} />
              <Route path='/aboutVideos' element={employee ? <AboutVideos /> : <Navigate to={'/login'} />} />
              <Route path='/aboutImpacts' element={employee ? <AboutImpacts /> : <Navigate to={'/login'} />} />
              <Route path='/aboutWriteUp' element={employee ? <AboutWriteUps /> : <Navigate to={'/login'} />} />
              <Route path='/aboutVideo' element={employee ? <AboutVodeo /> : <Navigate to={'/login'} />} />
              <Route path='/aboutSliders' element={employee ? <AboutSliders /> : <Navigate to={'/login'} />} />
              <Route path='/textSliders' element={employee ? <TextSliders /> : <Navigate to={'/login'} />} />
              <Route path='/aboutTopMainTexts' element={employee ? <AboutTopMainTexts /> : <Navigate to={'/login'} />} />
              <Route path='/aboutTopChangingTexts' element={employee ? <AboutTopChangingTexts /> : <Navigate to={'/login'} />} />

              <Route path='/others' element={employee ? <Others /> : <Navigate to={'/login'} />} />
              <Route path='/events' element={employee ? <Events /> : <Navigate to={'/login'} />} />
              <Route path='/subServices' element={employee ? <SubServices /> : <Navigate to={'/login'} />} />
              <Route path='/impacts' element={employee ? <Impacts /> : <Navigate to={'/login'} />} />
              <Route path='/expoWriteUp' element={employee ? <ExpoWriteUp /> : <Navigate to={'/login'} />} />
              <Route path='/programs' element={employee ? <Programs /> : <Navigate to={'/login'} />} />
              <Route path='/books' element={employee ? <Books /> : <Navigate to={'/login'} />} />
              <Route path='/exhibitions' element={employee ? <Exhibitions /> : <Navigate to={'/login'} />} />
              <Route path='/bookSliders' element={employee ? <BookSliders /> : <Navigate to={'/login'} />} />
              <Route path='/magazineSliders' element={employee ? <MagazineSliders /> : <Navigate to={'/login'} />} />

              <Route path='/serviceScreen' element={employee ? <ServiceScreen /> : <Navigate to={'/login'} />} />

              <Route path='/blogs' element={employee ? <Blogs /> : <Navigate to={'/login'} />} />
              <Route path='/featuredBlogs' element={employee ? <FeaturedBlogs /> : <Navigate to={'/login'} />} />

              <Route path='/settingsScreen' element={employee ? <SettingsScreen /> : <Navigate to={'/login'} />} />
              <Route path='/socialLinks' element={employee ? <SocialLinks /> : <Navigate to={'/login'} />} />
              <Route path='/appSettings' element={employee ? <AppSettings /> : <Navigate to={'/login'} />} />
              <Route path='/contactInfos' element={employee ? <ContactInfosScreen /> : <Navigate to={'/login'} />} />
              <Route path='/formResponses' element={employee ? <Contacts /> : <Navigate to={'/login'} />} />
              <Route path='/usersScreen' element={employee ? <UsersScreen /> : <Navigate to={'/login'} />} />

              <Route path='/tagsScreens' element={employee ? <TagsScreens /> : <Navigate to={'/login'} />} />
              <Route path='/imageGalleryTags' element={employee ? <ImageGalleryTags /> : <Navigate to={'/login'} />} />
              <Route path='/articleTags' element={employee ? <ArticleTags /> : <Navigate to={'/login'} />} />
              <Route path='/successStoryTags' element={employee ? <SuccessStoryTags /> : <Navigate to={'/login'} />} />
              <Route path='/imageGalleries' element={employee ? <ImageGalleries /> : <Navigate to={'/login'} />} />

              <Route path='/footerScreen' element={employee ? <FooterScreen /> : <Navigate to={'/login'} />} />

            </Routes>

          </section>
        </main>
      </BrowserRouter>
    </section>
  )
}

export default App
