import { useState } from "react"
import axios from "axios"
import Form from "../../../Partials/Layouts/Forms/Form"
import { ABOUT_WRITE_UPS_API } from "../../../../Utilities/APIs"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import CustomEditor from "../../../Partials/Elements/CustomEditor/CustomEditor"


function CreateAboutWriteUp({

    setShowCreateForm, setShowModal, triggerFetch

}) {
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')


    async function handleSubmit(e) {

        e.preventDefault()

        const itemData = { title, description }

        const response = await axios.post(ABOUT_WRITE_UPS_API, itemData)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>

            <ShortTextInput
                label={`Title`}
                value={title}
                placeholder={`Enter Title`}
                setState={setTitle}
            />

            <CustomEditor
                setState={setDescription}
            />

            <FormSubmitButton text='Create About Write Up' />
        </Form>
    )
}

export default CreateAboutWriteUp