import React from 'react'
import { WORK_FEATURES_API } from '../../../Utilities/APIs'
import INTCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/INTCRUD/INTCRUD'

function WorkFeatures() {
    return (
        <INTCRUD
            // common props
            api={WORK_FEATURES_API}
            screenTopicSingular='Work Feature'
            screenTopicPlural='Work Features'


            // crud header props
            showTotalInHeading


            // crud props
            allowCreate
            allowUpdate
            allowDelete

            //extra field props
            textDBField='precedence'
            textName='Precedence'
        />
    )
}

export default WorkFeatures