import React from 'react'
import CRUDBoilerPlate from '../../CRUDBoilerPlate/CRUDBoilerPlate'

function DLPTSCRUD({
    // common props
    api,
    screenTopicSingular,
    screenTopicPlural,

    // crud header props
    showTotalInHeading,

    // action button props
    extraActionButton,
    extraActionButtonHandleClick,
    extraActionButtonChildren,

    // crud props
    allowCreate,
    allowUpdate,
    allowDelete,

    //extra field props
    textDBField,
    textName,
    longTextDBField, longTextName,
    // select props
    selectApi,
    selectType,
    selectDBField,
    selectName,
}) {
    return (
        <CRUDBoilerPlate
            // common props
            api={api}
            screenTopicSingular={screenTopicSingular}
            screenTopicPlural={screenTopicPlural}
            // crud header props
            showTotalInHeading={showTotalInHeading}
            // action button props
            extraActionButton={extraActionButton}
            extraActionButtonHandleClick={extraActionButtonHandleClick}
            extraActionButtonChildren={extraActionButtonChildren}
            // crud props
            allowCreate={allowCreate}
            allowUpdate={allowUpdate}
            allowDelete={allowDelete}
            // field props
            hasDescription

            //extra field props
            hasText
            textDBField={textDBField}
            textName={textName}
            hasLongText
            longTextDBField={longTextDBField}
            longTextName={longTextName}

            // select props
            hasSelect
            selectApi={selectApi}
            selectType={selectType}
            selectDBField={selectDBField}
            selectName={selectName}
        />
    )
}

export default DLPTSCRUD