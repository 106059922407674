import { useState, useEffect } from "react"
import axios from "axios"
import { ABOUTS_API, IMAGE_URL } from "../../../../Utilities/APIs"

function ViewAbout({

    //common props
    targetID, employee

}) {

    const [about, setAbout] = useState(null)

    useEffect(() => {

        async function fetchAndSetAbout() {

            const { data } = await axios.get(ABOUTS_API + targetID)
            setAbout(data);
        }
        fetchAndSetAbout()
    }, [targetID])

    return (
        <div className="crud_view_content">

            {about &&
                <>
                    <h1>Title</h1>
                    <p>{about.title}</p>

                    <h1>Subtitle</h1>
                    <p>{about.subtitle}</p>

                    <h1>Description</h1>
                    <p>{about.description}</p>

                    <h1>Image</h1>
                    <img src={IMAGE_URL + about.image} alt="" />

                    <h1>Button Text One</h1>
                    <p>{about.buttonText1}</p>

                    <h1>Button Text Two</h1>
                    <p>{about.buttonText2}</p>

                </>
            }
        </div>
    )
}

export default ViewAbout