import React from 'react'
import { EVENTS_API, SERVICES_API } from '../../../Utilities/APIs'
import DNPTSCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/DNPTSCRUD/DNPTSCRUD'

function Events() {
  return (
    <DNPTSCRUD

      // common props
      api={EVENTS_API}
      screenTopicSingular='Event'
      screenTopicPlural='Events'

      // crud header props
      showTotalInHeading

      // crud props
      allowCreate
      allowUpdate
      allowDelete

      textDBField='precedence'
      textName='Precedence'

      // select props
      selectApi={SERVICES_API}
      selectType="parent-child"
      selectDBField='service'
      selectName='Service'

      // field props
      maxPrecedence={6}

    />
  )
}

export default Events