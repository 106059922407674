import { useEffect, useState } from "react"
import axios from "axios"
import Form from "../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { SERVICES_API } from "../../../Utilities/APIs"


function UpdateBook({

    targetID, employee, setShowUpdateForm, setShowModal, triggerFetch

}) {


    
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [image, setImage] = useState('')
    const [pdf, setPdf] = useState('')


    useEffect(() => {

        async function fetchAndSetServiceInfo() {

            const { data } = await axios.get(SERVICES_API + 'getSingleBook/' + targetID)
            setName(data.name);
            setDescription(data.description);
            setImage(data.image);
            setPdf(data.pdf);
        }
        fetchAndSetServiceInfo()
    }, [targetID])

    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()

        itemData.append('name', name)
        itemData.append('description', description)
        itemData.append('image', image)
        itemData.append('pdf', pdf)

        const response = await axios.patch(SERVICES_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label={`Name`}
                value={name}
                placeholder={`Enter Name`}
                setState={setName}
            />

            <ShortTextInput
                label={`Description`}
                value={description}
                placeholder={`Enter Description`}
                setState={setDescription}
            />
            <ImageInput
                fieldId='1'
                state={image}
                setState={setImage}
                allowUpdateImage
            >
                Upload Card Image
            </ImageInput>
            <ImageInput
                fieldId='2'
                state={pdf}
                setState={setPdf}
                allowUpdateImage
            >
                Upload Pdf
            </ImageInput>

            <FormSubmitButton text='Update Service' />
        </Form>
    )
}

export default UpdateBook