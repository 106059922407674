import React from "react";
import { ABOUT_VIDEOS_API } from "../../../../Utilities/APIs";
import axios from "axios";
import { useState } from "react";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import CustomEditor from "../../../Partials/Elements/CustomEditor/CustomEditor";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";

function CreateVideo({ setShowCreateForm, setShowModal, triggerFetch }) {
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [videoLink, setVideoLink] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();

    // const itemData = { title: image, description };
    itemData.append("description", description);
    itemData.append("image", image);
    itemData.append("videoLink", videoLink);

    const response = await axios.post(ABOUT_VIDEOS_API, itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ImageInput fieldId="1" setState={setImage} allowCreateImage>
        Upload Image
      </ImageInput>

      <ShortTextInput
        label={`Video Link`}
        value={videoLink}
        placeholder={`Enter Video Link`}
        setState={setVideoLink}
      />

      <CustomEditor setState={setDescription} />

      <FormSubmitButton text="Create About video" />
    </Form>
  );
}

export default CreateVideo;
