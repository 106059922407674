import { useState, useEffect } from "react"
import axios from "axios"

import { FEATURED_ARTICLES_API } from "../../../../Utilities/APIs"

function ViewFeaturedArticle({

    //common props
    targetID, employee

}) {

    const [featuredArticle, setFeaturedArticle] = useState(null)

    useEffect(() => {
        const config = {
            headers: {
                Authorization: `Bearer ${employee.token}`
            }
        }

        async function fetchAndSetFeaturedArticle() {

            const { data } = await axios.get(FEATURED_ARTICLES_API + targetID ,config)
            setFeaturedArticle(data);
        }
        fetchAndSetFeaturedArticle()
    }, [targetID ,employee.token])

    return (
        <div className="crud_view_content">

            {featuredArticle &&
                <>
                    <h1>Name</h1>
                    <p>{featuredArticle.article?.name}</p>

                    <h1>Precedence</h1>
                    <p>{featuredArticle.precedence}</p>

                </>
            }
        </div>
    )
}

export default ViewFeaturedArticle