import { useState, useEffect } from "react"
import axios from "axios"
import { IMAGE_URL, MAGAZINE_SLIDERS_API } from "../../../../Utilities/APIs"

function ViewMagazineSlider({

    //common props
    targetID, employee

}) {

    const [magazineslider, setMagazineSlider] = useState(null)

    useEffect(() => {

        async function fetchAndSetMagazineSliderInfo() {

            const { data } = await axios.get(MAGAZINE_SLIDERS_API + targetID)
            setMagazineSlider(data);
        }
        fetchAndSetMagazineSliderInfo()
    }, [targetID])

    return (
        <div className="crud_view_content">

            {magazineslider &&
                <>
                    <h1>Name</h1>
                    <p>{magazineslider.name}</p>

                    <h1>Description</h1>
                    <p>{magazineslider.description}</p>

                    <h1>Image</h1>
                    <img src={IMAGE_URL + magazineslider.image} alt="" />

                    <h1>Link</h1>
                    <p>{magazineslider.link}</p>

                    <h1>Button Text One</h1>
                    <p>{magazineslider.buttonText1}</p>

                    <h1>Button Text Two</h1>
                    <p>{magazineslider.buttonText2}</p>


                </>
            }
        </div>
    )
}

export default ViewMagazineSlider