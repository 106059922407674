import React from 'react'
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard'
import { RiArticleLine,  RiFolder5Line, RiFontColor, RiImageLine, RiVideoChatLine } from 'react-icons/ri'

function AboutScreen() {
    return (
        <NavCardList numOfCards={'eight'}>
            <NavCard cardName={'About Write Up'} navCardLink={'/aboutWriteUp'} ><RiArticleLine /></NavCard>
            <NavCard cardName={'About Sliders'} navCardLink={'/aboutSliders'} ><RiImageLine /></NavCard>
            <NavCard cardName={'Text Slider'} navCardLink={'/textSliders'} ><RiFontColor /></NavCard>
            <NavCard cardName={'About Impacts'} navCardLink={'/aboutImpacts'} ><RiFolder5Line /></NavCard>
            <NavCard cardName={'About Top Main Texts'} navCardLink={'/aboutTopMainTexts'} ><RiFolder5Line /></NavCard>
            <NavCard cardName={'About Top Changing Texts'} navCardLink={'/aboutTopChangingTexts'} ><RiFolder5Line /></NavCard>
            <NavCard cardName={'About Impact Titles'} navCardLink={'/aboutImpactTitles'} ><RiFolder5Line /></NavCard>
            <NavCard cardName={'About Video'} navCardLink={'/aboutVideo'} ><RiVideoChatLine /></NavCard>

        </NavCardList>
    )
}

export default AboutScreen