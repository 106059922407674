import { PROGRAMS_API, SERVICES_API } from "../../../Utilities/APIs";
import DLPTSCRUD from "../../Partials/Layouts/CRUDs/CRUDLayouts/DLPTSCRUD/DLPTSCRUD";

function Programs() {
  return (
    <DLPTSCRUD
      // common props
      api={PROGRAMS_API}
      screenTopicSingular="Program"
      screenTopicPlural="Programs"
      // crud header props
      showTotalInHeading
      // crud props
      allowCreate
      allowUpdate
      allowDelete
      //extra field props
      textDBField="precedence"
      textName="Precedence"
      longTextDBField='title'
      longTextName='Title'

      // select props
      selectApi={SERVICES_API}
      selectType="parent-child"
      selectDBField="service"
      selectName="Service"
    />
  );
}

export default Programs;
