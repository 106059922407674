import { useState, useEffect } from "react"
import axios from "axios"
import { ARTICLES_API, IMAGE_URL } from "../../../Utilities/APIs"

function ViewArticle({

    //common props
    targetID, employee

}) {

    const [articleInfo, setArticleInfo] = useState(null)

    useEffect(() => {

        async function fetchAndSetArticleInfo() {

            const { data } = await axios.get(ARTICLES_API + 'getSingleArticle/' + targetID)
            setArticleInfo(data);
        }
        fetchAndSetArticleInfo()
    }, [targetID])

    return (
        <div className="crud_view_content">

            {articleInfo &&
                <>
                    <h1>Service</h1>
                    <p>{articleInfo.service?.name}</p>

                    <h1>Name</h1>
                    <p>{articleInfo.name}</p>

                    <h1>News Portal</h1>
                    <p>{articleInfo.tag}</p>

                    <h1>Link</h1>
                    <p>{articleInfo.link}</p>

                    <h1>Image</h1>
                    <img src={IMAGE_URL + articleInfo.image} alt="" />
                    
                    <h1>Publish Date</h1>
                    <p>{new Date(articleInfo.publishDate).toLocaleDateString()}</p>

                    <h1>Precedence</h1>
                    <p>{articleInfo.precedence}</p>

                </>
            }
        </div>
    )
}

export default ViewArticle