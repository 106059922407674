import { useState, useEffect } from "react"
import axios from "axios"
import { HOME_PAGE_SECTION_TITLES_API } from "../../../../Utilities/APIs"

function ViewHomePageSectionTitle({

    //common props
    targetID, employee

}) {

    const [homePageSectionTitle, SetHomePageSectionTitle] = useState(null)

    useEffect(() => {

        async function fetchAndSethomePageSectionTitle() {

            const { data } = await axios.get(HOME_PAGE_SECTION_TITLES_API + targetID)
            SetHomePageSectionTitle(data);
        }
        fetchAndSethomePageSectionTitle()
    }, [targetID])

    return (
        <div className="crud_view_content">

            {homePageSectionTitle &&
                <>
                    <h1>Work Feature title</h1>
                    <p>{homePageSectionTitle.workFeaturetitle}</p>

                    <h1>Service Section title</h1>
                    <p>{homePageSectionTitle.serviceSectiontitle}</p>

                    <h1>Service Section Subtitle</h1>
                    <p>{homePageSectionTitle.serviceSectionSubtitle}</p>

                    <h1>Testimonial Section title</h1>
                    <p>{homePageSectionTitle.testimonialSectiontitle}</p>

                    <h1>Testimonial Section Subtitle</h1>
                    <p>{homePageSectionTitle.testimonialSectionSubtitle}</p>

                    <h1>Success Story Section title</h1>
                    <p>{homePageSectionTitle.successStorySectiontitle}</p>

                    <h1>Success Story Section Subtitle</h1>
                    <p>{homePageSectionTitle.successStorySectionSubtitle}</p>

                    <h1>Media Section title</h1>
                    <p>{homePageSectionTitle.mediaSectiontitle}</p>

                    <h1>Media Section Subtitle</h1>
                    <p>{homePageSectionTitle.mediaSectionSubtitle}</p>

                </>
            }
        </div>
    )
}

export default ViewHomePageSectionTitle