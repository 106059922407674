import { TEXT_SLIDERS_API } from "../../../Utilities/APIs"
import TCRUD from "../../Partials/Layouts/CRUDs/CRUDLayouts/TCRUD/TCRUD"

function TextSliders() {
    return (
        <TCRUD
            // common props
            api={TEXT_SLIDERS_API}
            screenTopicSingular='Text Slider'
            screenTopicPlural='Text Sliders'

            // crud header props
            showTotalInHeading

            // crud props
            allowCreate
            allowUpdate
            allowDelete


            //extra field props
            textDBField='title'
            textName='Title'
        />
    )
}

export default TextSliders