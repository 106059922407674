import { ABOUT_IMPACT_TITLES_API,  } from "../../../Utilities/APIs"
import TCRUD from "../../Partials/Layouts/CRUDs/CRUDLayouts/TCRUD/TCRUD"

function FooterTexts() {
    return (
        <TCRUD
            // common props
            api={ABOUT_IMPACT_TITLES_API}
            screenTopicSingular='About Impact Title'
            screenTopicPlural='About Impact Titles'

            // crud header props
            showTotalInHeading

            // crud props
            // allowCreate
            allowUpdate
            // allowDelete


            //extra field props
            textDBField='title'
            textName='Title'
        />
    )
}

export default FooterTexts