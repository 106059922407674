import { useState, useEffect } from "react"
import axios from "axios"
import { HOME_SLIDERS_API, IMAGE_URL } from "../../../../Utilities/APIs"

function ViewHeroSlider({

    //common props
    targetID, employee

}) {

    const [slider, setSlider] = useState(null)

    useEffect(() => {

        async function fetchAndSetSliderInfo() {

            const { data } = await axios.get(HOME_SLIDERS_API + targetID)
            setSlider(data);
        }
        fetchAndSetSliderInfo()
    }, [targetID])

    return (
        <div className="crud_view_content">

            {slider &&
                <>
                    <h1>Title</h1>
                    <p>{slider.title}</p>

                    <h1>Description</h1>
                    <p>{slider.description}</p>

                    <h1>Image</h1>
                    <img src={IMAGE_URL + slider.image} alt="" />

                    <h1>Button Text One</h1>
                    <p>{slider.buttonText1}</p>

                    <h1>Button Text Two</h1>
                    <p>{slider.buttonText2}</p>

                    <h1>Service One</h1>
                    <p>{slider.service1}</p>

                    <h1>Service Two</h1>
                    <p>{slider.service2}</p>

                    <h1>Service Three</h1>
                    <p>{slider.service3}</p>

                </>
            }
        </div>
    )
}

export default ViewHeroSlider