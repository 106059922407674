import { RiBookLine, RiHonourLine, RiImage2Line } from "react-icons/ri"
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard"
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList"

function TagsScreens() {
  return (
    <NavCardList numOfCards={'four'}>
        <NavCard cardName={'Image Tags'} navCardLink={'/imageGalleryTags'} ><RiImage2Line /></NavCard>
        <NavCard cardName={'Article Tags'} navCardLink={'/articleTags'} ><RiBookLine /></NavCard>
        <NavCard cardName={'Mostafiz Global Stance Tags'} navCardLink={'/successStoryTags'} ><RiHonourLine /></NavCard>
   </NavCardList>
  )
}

export default TagsScreens