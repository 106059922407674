import SmallSideBarItem from './SmallSideBarItem/SmallSideBarItem';

import { RiFile3Line, RiFocus2Line, RiGroupLine, RiHome2Line, RiPriceTag2Line, RiServiceLine, RiShieldStarLine, RiVipCrown2Line } from 'react-icons/ri';

import './SideBar.css';

function SideBar() {

    return (
        <>
            <section className="small_sidebar">
                <div className='container'>

                    <SmallSideBarItem
                        link='/'
                        dataTip='Home Page'
                        dataFor='dashboard'
                    >
                        <RiHome2Line />
                    </SmallSideBarItem>
                    <SmallSideBarItem
                        link='/aboutScreen'
                        dataTip='About Page'
                        dataFor='about'
                    >
                        <RiShieldStarLine />
                    </SmallSideBarItem>
                    <SmallSideBarItem
                        link='/serviceScreen'
                        dataTip='Services'
                        dataFor='services'
                    >
                        <RiServiceLine />
                    </SmallSideBarItem>
                    <SmallSideBarItem
                        link='/others'
                        dataTip='Others Elements'
                        dataFor='others'
                    >
                        <RiVipCrown2Line />
                    </SmallSideBarItem>
                    {/* <SmallSideBarItem
                        link='/blogs'
                        dataTip='Blogs'
                        dataFor='blogs'
                    >
                        <RiBook2Line />
                    </SmallSideBarItem> */}

                    <SmallSideBarItem
                        link='/usersScreen'
                        dataTip='Users'
                        dataFor='Users'
                    >
                        <RiGroupLine />
                    </SmallSideBarItem>
                    <SmallSideBarItem
                        link='/tagsScreens'
                        dataTip='Tags'
                        dataFor='tags'
                    >
                       <RiPriceTag2Line />
                    </SmallSideBarItem>

                    <SmallSideBarItem
                        link='/footerScreen'
                        dataTip='Footer'
                        dataFor='footer'
                    >
                        <RiFocus2Line />
                    </SmallSideBarItem>

                    <SmallSideBarItem
                        link='/formResponses'
                        dataTip='Form Responses'
                        dataFor='formResponses'
                    >
                       <RiFile3Line />
                    </SmallSideBarItem>
                </div>
            </section>
        </>
    )
}

export default SideBar
