import { useEffect, useState } from "react"
import axios from "axios"
// import { RiVideoAddLine } from "react-icons/ri"

import { ARTICLES_API, ARTICLE_TAGS_API, SERVICES_API } from "../../../Utilities/APIs"
import Form from "../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import Modal from "../../Partials/Elements/Modal/Modal"
import MediaGallery from "../../Partials/Layouts/MediaGallery/MediaGallery"
import SwitchInput from "../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput"
// import CustomEditor from "../../Partials/Elements/CustomEditor/CustomEditor"


function CreateArticle({ setShowCreateForm, setShowModal, triggerFetch }) {

    const [showSecondModal, setShowSecondModal] = useState(false);
    const [showMediaGallery, setShowMediaGallery] = useState(false);
    // const [activeTab, setActiveTab] = useState(1);

    const [service, setService] = useState('')
    const [name, setName] = useState('')
    const [tag, setTag] = useState('')
    const [articleTag, setArticleTag] = useState('')
    const [image, setImage] = useState('')
    const [link, setLink] = useState('')
    const [publishDate, setPublishDate] = useState('')
    const [precedence, setPrecedence] = useState(0)
    const [hasService, setHasService] = useState(false)

    const [services, setServices] = useState(null)

    const [articleTags, setArticleTags] = useState(null);


    useEffect(() => {
      async function getArticleTags() {
        const { data } = await axios.get(ARTICLE_TAGS_API);
        setArticleTags(data);
      }
      getArticleTags();
    }, []);

    useEffect(() => {

        async function fetchAndSetServices() {
            const { data } = await axios.get(SERVICES_API)
            setServices(data)
        }
        fetchAndSetServices()


    }, [])
    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        if (service && service !== '') itemData.append('service', service)
        itemData.append('hasService', hasService)
        itemData.append('name', name)
        itemData.append('articleTag', articleTag)
        itemData.append('tag', tag)
        itemData.append('link', link)
        itemData.append('image', image)
        itemData.append('publishDate', publishDate)
        itemData.append('precedence', precedence)

        const response = await axios.post(ARTICLES_API, itemData)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);
            triggerFetch()
        }
    }

    function handleClose() {
        setShowSecondModal(false)
        setShowMediaGallery(false)
    }

    return (
        <>


            <Form onSubmit={handleSubmit} hasImage>
                <SwitchInput
                    label={`Has Service?`}
                    toggleSwitch={() => setHasService((prevState) => !prevState)}
                    checked={hasService}
                />
                <div className={`nav_content active`}>
                    {
                        hasService &&
                        <div className="input_group">
                            <label className='input_field_label'>
                                Service
                            </label>
                            <select
                                value={service}
                                onChange={(e) => setService(e.target.value)}
                                className="input_field"
                                placeholder="propertyCategory"
                            >
                                <option value="0" hidden>Select Service</option>
                                {services?.map((service => (
                                    <option value={service._id} key={service._id}>
                                        {service?.name}
                                    </option>
                                )))}
                            </select>
                        </div>
                    }
                    <ShortTextInput
                        label={`Name`}
                        value={name}
                        placeholder={`Enter Name`}
                        setState={setName}
                    />
                     <div className="input_group">
                            <label className='input_field_label'>
                              Article Tag
                            </label>
                            <select
                                value={articleTag}
                                onChange={(e) => setArticleTag(e.target.value)}
                                className="input_field"
                                placeholder="propertyCategory"
                            >
                                <option value="0" hidden>Select Article Tag</option>
                                {articleTags?.map((articleTag => (
                                    <option value={articleTag._id} key={articleTag._id}>
                                        {articleTag?.name}
                                    </option>
                                )))}
                            </select>
                        </div>
                    <ShortTextInput
                        label={`News Portal`}
                        value={tag}
                        placeholder={`Enter News Portal`}
                        setState={setTag}
                    />
                    <ShortTextInput
                        label={`Link`}
                        value={link}
                        placeholder={`Enter Link`}
                        setState={setLink}
                    />

                    <>
                        <h1 className="input_field_label">Publish Date</h1>
                        <input
                            className="input_field"
                            type="date"
                            value={publishDate}
                            onChange={(e) => setPublishDate(e.target.value)}
                        />
                    </>
                    <ImageInput
                        fieldId='1'
                        state={image}
                        setState={setImage}
                        allowCreateImage
                    >
                        Upload Card Image
                    </ImageInput>
                    <ShortTextInput
                        label={`Precedence`}
                        value={precedence}
                        placeholder={`Enter Precedence`}
                        setState={setPrecedence}
                    />

                </div>
                {/* <div className={`nav_content ${activeTab === 2 ? 'active' : ''}`}>
                    <CustomEditor
                        setState={setDescription}
                    />
                </div> */}


                <FormSubmitButton text='Create Article' />
            </Form>

            {showSecondModal &&
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={
                        `Media gallery`
                    }
                >

                    {showMediaGallery &&
                        <MediaGallery
                            initialScreen='article'
                        />
                    }
                </Modal>
            }
        </>
    )
}

export default CreateArticle