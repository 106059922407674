import { useState } from "react"
import axios from "axios"
import Form from "../../../Partials/Layouts/Forms/Form"
import { ABOUT_WRITE_UPS_API } from "../../../../Utilities/APIs"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import CustomEditor from "../../../Partials/Elements/CustomEditor/CustomEditor"
import { useEffect } from "react"


function UpdateAboutWriteUp({

    targetID, setShowUpdateForm, setShowModal, triggerFetch

}) {
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')

    useEffect(() => {

        async function fetchAndSetAboutWriteUp() {

            const { data } = await axios.get(ABOUT_WRITE_UPS_API + targetID)
            setTitle(data.title);
            setDescription(data.description);
        }
        fetchAndSetAboutWriteUp()
    }, [targetID])

    async function handleSubmit(e) {

        e.preventDefault()

        const itemData = { title, description }

        const response = await axios.patch(ABOUT_WRITE_UPS_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>

            <ShortTextInput
                label={`Title`}
                value={title}
                placeholder={`Enter Title`}
                setState={setTitle}
            />
            <>
                <label className="input_field_label ">Description</label>
                <CustomEditor
                    setState={setDescription}
                    data={description}
                />
            </>

            <FormSubmitButton text='Update About Write Up' />
        </Form>
    )
}

export default UpdateAboutWriteUp