import { useEffect, useState } from "react"
import axios from "axios"
import Form from "../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { EXHIBITIONS_API, SERVICES_API } from "../../../Utilities/APIs"
import LinkInput from "../../Partials/Layouts/Forms/FormInputs/LinkInput/LinkInput"


function CreateExhibition({

    employee, setShowCreateForm, setShowModal, triggerFetch

}) {

    const [service, setService] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [date, setDate] = useState('')
    const [websiteLink, setWebsiteLink] = useState('')
    const [image, setImage] = useState('')
    const [location, setLocation] = useState('')
    const [services, setServices] = useState(null)


    useEffect(() => {

        async function fetchAndSetServices() {
            const { data } = await axios.get(SERVICES_API)
            setServices(data)
        }
        fetchAndSetServices()


    }, [])

    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()

        itemData.append('service', service)
        itemData.append('title', title)
        itemData.append('description', description)
        itemData.append('image', image)
        itemData.append('date', date)
        itemData.append('websiteLink', websiteLink)
        itemData.append('location', location)


        const response = await axios.post(EXHIBITIONS_API, itemData)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <div className="input_group">
                <label className='input_field_label'>
                    Service
                </label>
                <select
                    value={service}
                    onChange={(e) => setService(e.target.value)}
                    className="input_field"
                    placeholder="propertyCategory"
                >
                    <option value="0" hidden>Select Service</option>
                    {services?.map((service => (
                        <option value={service._id} key={service._id}>
                            {service?.name}
                        </option>
                    )))}
                </select>
            </div>
            <ShortTextInput
                label={`Title`}
                value={title}
                placeholder={`Enter Title`}
                setState={setTitle}
            />

            <ShortTextInput
                label={`Description`}
                value={description}
                placeholder={`Enter Description`}
                setState={setDescription}
            />
            <>
                <h1 className="input_field_label">Date</h1>
                <input
                    className="input_field"
                    type="date"
                    id="date"
                    name="date"
                    onChange={(e) => setDate(e.target.value)}
                />
            </>
            <ImageInput
                fieldId='1'
                state={image}
                setState={setImage}
                allowCreateImage
            >
                Upload Card Image
            </ImageInput>
            <LinkInput
                label={`Website Link`}
                value={websiteLink}
                placeholder={`Enter Website Link`}
                setState={setWebsiteLink}
            />

            <ShortTextInput
                label={`Location`}
                value={location}
                placeholder={`Enter Location`}
                setState={setLocation}
            />

            <FormSubmitButton text='Create Exhibition' />
        </Form>
    )
}

export default CreateExhibition