import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../../../../Utilities/APIs';

import MediaCard from './MediaCard/MediaCard'
import AddNewMedia from './AddNewMedia/AddNewMedia'

import './MediaLists.css'

function MediaLists({ selectedCollection }) {

    const [toggleFetch, setToggleFetch] = useState(false)
    const [mediaCards, setMediaCards] = useState(null);

    useEffect(() => {
        if (selectedCollection !== 'initial') {
            async function getMedia() {
                const { data } = await axios.get(API_URL + 'media/getMediaFromMediaCollection/' + selectedCollection)
                setMediaCards(data)
            }
            getMedia()
        }
    }, [selectedCollection, toggleFetch]);

    function triggerFetch() {
        setToggleFetch(prevState => !prevState)
    }

    return (
        <div className='media_lists'>
            {mediaCards?.map(card => (
                <MediaCard
                    key={card._id}
                    image={card.media}
                />
            ))}
            <AddNewMedia
            selectedCollection={selectedCollection}
                triggerFetch={triggerFetch}
            />
        </div>
    )
}

export default MediaLists