import { useState, useEffect } from "react";
import axios from "axios";
import { ABOUT_VIDEOS_API, IMAGE_URL } from "../../../../Utilities/APIs";

function ViewAboutVodeo({
  //common props
  targetID,
  employee,
}) {
  const [aboutVodeo, setAboutVodeo] = useState(null);

  useEffect(() => {
    async function fetchAndSetAboutVodeo() {
      const { data } = await axios.get(ABOUT_VIDEOS_API + targetID);
      setAboutVodeo(data);
    }
    fetchAndSetAboutVodeo();
  }, [targetID]);
  
  return (
    <div className="crud_view_content">
      {aboutVodeo && (
        <>
          <h1>video Link </h1>
          <p>{aboutVodeo.videoLink}</p>

          <h1>Image</h1>
          <img src={IMAGE_URL + aboutVodeo?.image} alt="" />

          <h1>Description</h1>
          <div dangerouslySetInnerHTML={{ __html: aboutVodeo?.description }} />
        </>
      )}
    </div>
  );
}

export default ViewAboutVodeo;
