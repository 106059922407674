import { useEffect, useState } from "react"
import axios from "axios"
import Form from "../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { EXHIBITIONS_API, SERVICES_API } from "../../../Utilities/APIs"


function UpdateExhibition({

    targetID, employee, setShowUpdateForm, setShowModal, triggerFetch

}) {

    const [service, setService] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [date, setDate] = useState('')
    const [websiteLink, setWebsiteLink] = useState('')
    const [image, setImage] = useState('')
    const [location, setLocation] = useState('')
    const [services, setServices] = useState(null)


    useEffect(() => {

        async function fetchAndSetServices() {
            const { data } = await axios.get(SERVICES_API)
            setServices(data)
        }
        fetchAndSetServices()


    }, [])

    useEffect(() => {

        async function fetchAndSetExhibitionInfo() {

            const { data } = await axios.get(EXHIBITIONS_API + targetID)
            const dt = new Date(data.date)
            setService(data.service);
            setTitle(data.title);
            setDescription(data.description);
            setWebsiteLink(data.websiteLink);
            setDate(`${dt.toISOString().slice(0, 10)}`)
            setImage(data.image);
            setLocation(data.location);
        }
        fetchAndSetExhibitionInfo()
    }, [targetID])

    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()
        itemData.append('service', service)
        itemData.append('title', title)
        itemData.append('description', description)
        itemData.append('image', image)
        itemData.append('date', date)
        itemData.append('websiteLink', websiteLink)
        itemData.append('location', location)


        const response = await axios.patch(EXHIBITIONS_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <div className="input_group">
                <label className='input_field_label'>
                    Service
                </label>
                <select
                    value={service}
                    onChange={(e) => setService(e.target.value)}
                    className="input_field"
                    placeholder="propertyCategory"
                >
                    {services?.map((service => (
                        <option value={service._id} key={service._id}>
                            {service?.name}
                        </option>
                    )))}
                </select>
            </div>
            <ShortTextInput
                label={`Title`}
                value={title}
                placeholder={`Enter Title`}
                setState={setTitle}
            />

            <ShortTextInput
                label={`Description`}
                value={description}
                placeholder={`Enter Description`}
                setState={setDescription}
            />
            <>
                <h1 className="input_field_label">Date</h1>
                <input
                    value={date}
                    className="input_field"
                    type="date"
                    id="date"
                    name="date"
                    onChange={(e) => setDate(e.target.value)}
                />
            </>
            <ImageInput
                fieldId='1'
                state={image}
                setState={setImage}
                allowUpdateImage
            >
                Upload Card Image
            </ImageInput>
            <ShortTextInput
                label={`Website Link`}
                value={websiteLink}
                placeholder={`Enter Website Link`}
                setState={setWebsiteLink}
            />

            <ShortTextInput
                label={`Location`}
                value={location}
                placeholder={`Enter Location`}
                setState={setLocation}
            />

            <FormSubmitButton text='Update Exhibition' />
        </Form>
    )
}

export default UpdateExhibition