import { useState } from "react";
import axios from "axios";
import Form from "../../../Partials/Layouts/Forms/Form";
import { ABOUT_VIDEOS_API } from "../../../../Utilities/APIs";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import CustomEditor from "../../../Partials/Elements/CustomEditor/CustomEditor";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import { useEffect } from "react";

function UpdateAboutVideo({
  targetID,
  setShowUpdateForm,
  setShowModal,
  triggerFetch,
}) {
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [videoLink, setVideoLink] = useState("");

  //   const [aboutVodeo, setAboutVodeo] = useState(null);

  useEffect(() => {
    async function fetchAndSetAboutVodeo() {
      const { data } = await axios.get(ABOUT_VIDEOS_API + targetID);
      setDescription(data?.description);
      setImage(data?.image);
      setVideoLink(data?.videoLink);
    }
    fetchAndSetAboutVodeo();
  }, [targetID]);

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();

    // const itemData = { title: image, description };
    itemData.append("description", description);
    itemData.append("image", image);
    itemData.append("videoLink", videoLink);

    const response = await axios.patch(ABOUT_VIDEOS_API + targetID, itemData);

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowUpdateImage
      >
        Upload Image
      </ImageInput>
      <ShortTextInput
        label={`Video Link`}
        value={videoLink}
        placeholder={`Enter Video Link`}
        setState={setVideoLink}
      />

      <CustomEditor setState={setDescription} data={description} />

      <FormSubmitButton text="Update About video" />
    </Form>
  );
}

export default UpdateAboutVideo;
