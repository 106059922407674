import React from 'react'
import { ABOUT_VIDEOS_API } from '../../../Utilities/APIs'
import DITCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/DITCRUD/DITCRUD'

function AboutVideos() {
    return (
        <DITCRUD
            // common props
            api={ABOUT_VIDEOS_API}
            screenTopicSingular="About Video"
            screenTopicPlural="About Videos"

            // crud header props
            showTotalInHeading

            // crud props
            // allowCreate
            allowUpdate
            // allowDelete

            //extra field props
            textDBField="videoLink"
            textName="Video Link"


        />
    )
}

export default AboutVideos