import { useState, useEffect } from "react"
import axios from "axios"
import { BOOK_SLIDERS_API, IMAGE_URL } from "../../../../Utilities/APIs"

function ViewBookSlider({

    //common props
    targetID, employee

}) {

    const [Bookslider, setBookSlider] = useState(null)

    useEffect(() => {

        async function fetchAndSetBookSliderInfo() {

            const { data } = await axios.get(BOOK_SLIDERS_API + targetID)
            setBookSlider(data);
        }
        fetchAndSetBookSliderInfo()
    }, [targetID])

    return (
        <div className="crud_view_content">

            {Bookslider &&
                <>
                    <h1>Name</h1>
                    <p>{Bookslider.name}</p>

                    <h1>Description</h1>
                    <p>{Bookslider.description}</p>

                    <h1>Image</h1>
                    <img src={IMAGE_URL + Bookslider.image} alt="" />

                    <h1>Link</h1>
                    <p>{Bookslider.link}</p>

                    <h1>Button Text One</h1>
                    <p>{Bookslider.buttonText1}</p>

                    <h1>Button Text Two</h1>
                    <p>{Bookslider.buttonText2}</p>


                </>
            }
        </div>
    )
}

export default ViewBookSlider