import { RiFileCopyLine } from "react-icons/ri"

import { IMAGE_URL } from "../../../../../../Utilities/APIs"

function MediaCard({image}) {
    return (
        <div className="list_card">
            <div className="icon">
                <img src={IMAGE_URL + image} alt="thumb" />
            </div>
            <div className="copy_clipboard">
                <span>Image.jpg</span>
                <button 
                onClick={()=> {navigator?.clipboard?.writeText(IMAGE_URL + image)}}
                ><RiFileCopyLine /></button>
            </div>
        </div>
    )
}

export default MediaCard