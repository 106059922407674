import { IMPACTS_API, SERVICES_API } from "../../../Utilities/APIs"
import LTSCRUD from "../../Partials/Layouts/CRUDs/CRUDLayouts/LTSCRUD/LTSCRUD"

function Impacts() {
    return (
        <LTSCRUD
            // common props
            api={IMPACTS_API}
            screenTopicSingular="Impact"
            screenTopicPlural="Impacts"

            // crud header props
            showTotalInHeading

            // crud props
            allowCreate
            allowUpdate
            // allowDelete

            //extra field props
            textDBField="mainText"
            textName="Main Text"

            longTextDBField="subText"
            longTextName="Sub Text"


            // select props
            selectApi={SERVICES_API}
            selectType="parent-child"
            selectDBField='service'
            selectName='Service'
        />
    )
}

export default Impacts