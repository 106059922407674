import { HOME_VIDEOS_API } from "../../../Utilities/APIs"
import ITCRUD from "../../Partials/Layouts/CRUDs/CRUDLayouts/ITCRUD/ITCRUD"


function HomeVideos() {
    return (
        <ITCRUD

            // common props
            api={HOME_VIDEOS_API}
            screenTopicSingular='Home Video'
            screenTopicPlural='Home Videos'

            // crud header props
            showTotalInHeading

            // crud props
            // allowCreate
            allowUpdate
            // allowDelete

            //extra field props
            textDBField='videoLink'
            textName='Video Link'
        />
    )
}

export default HomeVideos